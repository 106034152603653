import React, { useState } from 'react';
import { Link as Link2 } from 'react-router-dom';
import properties from '../config/grid-data';
import withRouter from '../component/withrouter';
import Navbar from '../component/Navbar';
import Switcher from '../component/Switcher';
import Footer from '../component/Footer';

import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';

import Image1 from '../assets/images/property/single/1.jpg';
import Image2 from '../assets/images/property/single/2.jpg';
import Image3 from '../assets/images/property/single/3.jpg';
import Image4 from '../assets/images/property/single/4.jpg';
import Image5 from '../assets/images/property/single/5.jpg';

const images = [Image1, Image2, Image3, Image4, Image5];

function PropertyDetail(props) {
  const [photoIndex, setActiveIndex] = useState(0);
  const [isOpen, setOpen] = useState(false);

  const property = properties.find(
    user => user.id === parseInt(props.params.id),
  );

  const handleCLick = index => {
    setActiveIndex(index);
    setOpen(true);
  };

  return (
    <>
      <Navbar />
      {/* <!-- Hero Start --> */}
      <section className="relative mt-20 pb-16 md:pb-24">
        <div className="container-fluid">
          <div className="mt-4 md:flex">
            <div className="p-1 md:w-1/2 lg:w-1/2">
              <div className="group relative overflow-hidden">
                <img src={Image1} alt="" />
                <div className="absolute inset-0 duration-500 ease-in-out group-hover:bg-slate-900/70"></div>
                <div className="invisible absolute end-0 start-0 top-1/2 -translate-y-1/2 text-center group-hover:visible">
                  <Link2
                    to="#"
                    onClick={() => handleCLick(0)}
                    className="btn btn-icon lightbox rounded-full bg-blue-600 text-white hover:bg-blue-700">
                    <i className="uil uil-camera"></i>
                  </Link2>
                </div>
              </div>
            </div>

            <div className="md:w-1/2 lg:w-1/2">
              <div className="flex">
                <div className="w-1/2 p-1">
                  <div className="group relative overflow-hidden">
                    <img src={Image2} alt="" />
                    <div className="absolute inset-0 duration-500 ease-in-out group-hover:bg-slate-900/70"></div>
                    <div className="invisible absolute end-0 start-0 top-1/2 -translate-y-1/2 text-center group-hover:visible">
                      <Link2
                        to="#"
                        onClick={() => handleCLick(1)}
                        className="btn btn-icon lightbox rounded-full bg-blue-600 text-white hover:bg-blue-700">
                        <i className="uil uil-camera"></i>
                      </Link2>
                    </div>
                  </div>
                </div>

                <div className="w-1/2 p-1">
                  <div className="group relative overflow-hidden">
                    <img src={Image3} alt="" />
                    <div className="absolute inset-0 duration-500 ease-in-out group-hover:bg-slate-900/70"></div>
                    <div className="invisible absolute end-0 start-0 top-1/2 -translate-y-1/2 text-center group-hover:visible">
                      <Link2
                        to="#"
                        onClick={() => handleCLick(2)}
                        className="btn btn-icon lightbox rounded-full bg-blue-600 text-white hover:bg-blue-700">
                        <i className="uil uil-camera"></i>
                      </Link2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex">
                <div className="w-1/2 p-1">
                  <div className="group relative overflow-hidden">
                    <img src={Image4} alt="" />
                    <div className="absolute inset-0 duration-500 ease-in-out group-hover:bg-slate-900/70"></div>
                    <div className="invisible absolute end-0 start-0 top-1/2 -translate-y-1/2 text-center group-hover:visible">
                      <Link2
                        to="#"
                        onClick={() => handleCLick(3)}
                        className="btn btn-icon lightbox rounded-full bg-blue-600 text-white hover:bg-blue-700">
                        <i className="uil uil-camera"></i>
                      </Link2>
                    </div>
                  </div>
                </div>

                <div className="w-1/2 p-1">
                  <div className="group relative overflow-hidden">
                    <img src={Image5} alt="" />
                    <div className="absolute inset-0 duration-500 ease-in-out group-hover:bg-slate-900/70"></div>
                    <div className="invisible absolute end-0 start-0 top-1/2 -translate-y-1/2 text-center group-hover:visible">
                      <Link2
                        to="#"
                        onClick={() => handleCLick(4)}
                        className="btn btn-icon lightbox rounded-full bg-blue-600 text-white hover:bg-blue-700">
                        <i className="uil uil-camera"></i>
                      </Link2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-16 md:mt-24">
          <div className="md:flex">
            <div className="px-3 md:w-1/2 md:p-4 lg:w-2/3">
              <h4 className="text-2xl font-medium">{property?.name}</h4>

              <ul className="flex list-none items-center py-6">
                <li className="me-4 flex items-center lg:me-6">
                  <i className="uil uil-compress-arrows me-2 text-2xl text-blue-600 lg:text-3xl"></i>
                  <span className="lg:text-xl">{property?.square}sqf</span>
                </li>

                <li className="me-4 flex items-center lg:me-6">
                  <i className="uil uil-bed-double me-2 text-2xl text-blue-600 lg:text-3xl"></i>
                  <span className="lg:text-xl">{property?.beds} Beds</span>
                </li>

                <li className="flex items-center">
                  <i className="uil uil-bath me-2 text-2xl text-blue-600 lg:text-3xl"></i>
                  <span className="lg:text-xl">{property?.baths} Baths</span>
                </li>
              </ul>

              <p className="text-slate-400">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                voluptas sit aspernatur aut odit aut fugit, sed quia
                consequuntur magni dolores eos qui ratione voluptatem sequi
                nesciunt.
              </p>
              <p className="mt-4 text-slate-400">
                But I must explain to you how all this mistaken idea of
                denouncing pleasure and praising pain was born and I will give
                you a complete account of the system, and expound the actual
                teachings of the great explorer of the truth, the master-builder
                of human happiness.
              </p>
              <p className="mt-4 text-slate-400">
                Nor again is there anyone who loves or pursues or desires to
                obtain pain of itself, because it is pain, but because
                occasionally circumstances occur in which toil and pain can
                procure him some great pleasure.
              </p>

              <div className="mt-6 w-full border-0 leading-[0]">
                <iframe
                  title="iframe"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                  style={{ border: '0' }}
                  className="h-[500px] w-full"
                  allowFullScreen></iframe>
              </div>
            </div>

            <div className="mt-8 px-3 md:mt-0 md:w-1/2 md:p-4 lg:w-1/3">
              <div className="sticky top-20">
                <div className="rounded-md bg-slate-50 shadow dark:bg-slate-800 dark:shadow-gray-700">
                  <div className="p-6">
                    <h5 className="text-2xl font-medium">Price:</h5>

                    <div className="mt-4 flex items-center justify-between">
                      <span className="text-xl font-medium">
                        $ {property?.price}
                      </span>

                      <span className="py-0.75 h-6 rounded bg-blue-600/10 px-2.5 text-sm text-blue-600">
                        For Sale
                      </span>
                    </div>

                    <ul className="mt-4 list-none">
                      <li className="flex items-center justify-between">
                        <span className="text-sm text-slate-400">
                          Days on Hously
                        </span>
                        <span className="text-sm font-medium">124 Days</span>
                      </li>

                      <li className="mt-2 flex items-center justify-between">
                        <span className="text-sm text-slate-400">
                          Price per sq ft
                        </span>
                        <span className="text-sm font-medium">$ 186</span>
                      </li>

                      <li className="mt-2 flex items-center justify-between">
                        <span className="text-sm text-slate-400">
                          Monthly Payment (estimate)
                        </span>
                        <span className="text-sm font-medium">
                          $ 1497/Monthly
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="flex">
                    <div className="w-1/2 p-1">
                      <Link2
                        to="#"
                        className="btn w-full rounded-md bg-blue-600 text-white hover:bg-blue-700">
                        Book Now
                      </Link2>
                    </div>
                    <div className="w-1/2 p-1">
                      <Link2
                        to="#"
                        className="btn w-full rounded-md bg-blue-600 text-white hover:bg-blue-700">
                        Offer Now
                      </Link2>
                    </div>
                  </div>
                </div>

                <div className="mt-12 text-center">
                  <h3 className="mb-6 text-xl font-medium leading-normal text-black dark:text-white">
                    Have Question ? Get in touch!
                  </h3>

                  <div className="mt-6">
                    <Link2
                      to="/contact"
                      className="btn rounded-md border border-blue-600 bg-transparent text-blue-600 hover:bg-blue-600 hover:text-white">
                      <i className="uil uil-phone me-2 align-middle"></i>{' '}
                      Contact us
                    </Link2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen({ isOpen: false })}
          onMovePrevRequest={() =>
            setActiveIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setActiveIndex((photoIndex + 1) % images.length)
          }
        />
      )}

      <Footer />
      <Switcher />
    </>
  );
}

export default withRouter(PropertyDetail);
