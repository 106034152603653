import React, { useState } from 'react';
import { Link as Link2 } from 'react-router-dom';
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import Switcher from '../component/Switcher';
import BackgroudImage from '../assets/images/himalayaz-small.jpg';

import {
  GitHub,
  Gitlab,
  Linkedin,
  Youtube,
  Facebook,
  Instagram,
  Twitter,
  User,
  Mail,
  MessageCircle,
} from 'react-feather';
import axios from 'axios';

import Poperty2Image from '../assets/images/AI_generated.jpg';
import Poperty1Image from '../assets/images/content_base.jpg';
import Poperty3Image from '../assets/images/blog-3.jpg';

export default function BlogDetail1() {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [comments, setComments] = useState('');

  const submitFormHandler = async () => {
    const data = {
      name,
      email,
      comments,
    };
    try {
      axios
        .post(
          'https://himalayazform-default-rtdb.firebaseio.com/blogComments.json',
          data,
          {
            headers: { 'Content-Type': 'application/json' },
          },
        )
        .then(function (response) {
          setComments('');
          setName('');
          setEmail('');
          console.log('Form data sent successfully.', response);
        })
        .catch(function (error) {
          setComments('');
          setName('');
          setEmail('');
          console.log(error);
        });
      console.log('Form data sent successfully.');
    } catch (error) {
      console.error('Error sending form data:', error);
    }
  };
  return (
    <>
      <Navbar navClass="navbar-white" />

      <section
        style={{ backgroundImage: `url(${BackgroudImage})` }}
        className="relative table w-full  bg-cover bg-center bg-no-repeat py-32 lg:py-36">
        <div className="absolute inset-0 bg-black opacity-80"></div>
        <div className="container">
          <div className="mt-10 grid grid-cols-1 text-center">
            <h3 className="mb-3 text-2xl font-medium leading-snug tracking-wide text-white md:text-3xl md:leading-snug">
              The Future of NoCode and AI-Based Systems
            </h3>
          </div>
        </div>
      </section>
      <div className="relative">
        <div className="shape z-1 overflow-hidden text-white dark:text-slate-900">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"></path>
          </svg>
        </div>
      </div>

      <section className="relative py-16 md:py-24">
        <div className="container">
          <div className="grid grid-cols-1 gap-[30px] md:grid-cols-1 lg:grid-cols-12">
            <div className="order-2 md:order-1 lg:col-span-12">
              <div className="relative overflow-hidden rounded-xl shadow dark:shadow-gray-800">
                <img src={Poperty2Image} alt="" />

                <div className="p-6">
                  <p className="text-slate-400">
                    In recent years, there has been a surge in the popularity of
                    NoCode and AI-based systems. NoCode refers to the ability to
                    create applications and software without writing any code.
                    It's a powerful tool for businesses, as it enables
                    non-technical users to create software solutions to meet
                    their specific needs. AI-based systems, on the other hand,
                    are those that use machine learning algorithms to analyze
                    data and make predictions or decisions.
                  </p>
                  <p className="mt-3 rounded-ee-xl rounded-ss-xl border-x-4 border-blue-600 p-3 italic text-slate-400">
                    "The future of NoCode and AI-based systems looks bright. As
                    technology continues to advance, we can expect to see more
                    businesses adopting these tools to streamline their
                    operations, improve customer experiences, and increase
                    productivity. Here are a few reasons why: "
                  </p>
                  <p className="mt-3 text-slate-400">
                    NoCode and AI-based systems are the future of software
                    development. They offer businesses increased accessibility,
                    faster development, improved scalability, enhanced customer
                    experience, and reduced costs. At Himalayaz LLC, we have the
                    expertise to help businesses leverage these tools to achieve
                    their goals. Contact us today to learn more about how we can
                    help your business thrive in the digital age.
                  </p>
                </div>
              </div>

              <div className="mt-8 rounded-md p-6 shadow dark:shadow-gray-800">
                <h5 className="text-lg font-semibold">Leave A Comment:</h5>

                {/* <form className="mt-8"> */}
                <div className="mt-8 grid lg:grid-cols-12 lg:gap-6">
                  <div className="mb-5 lg:col-span-6">
                    <div className="text-left">
                      <label htmlFor="name" className="font-semibold">
                        Your Name:
                      </label>
                      <div className="form-icon relative mt-2">
                        <User className="absolute start-4 top-3 h-4 w-4" />
                        <input
                          name="name"
                          id="name"
                          type="text"
                          value={name}
                          onChange={e => {
                            setName(e.target.value);
                          }}
                          className="form-input ps-11"
                          placeholder="Name :"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mb-5 lg:col-span-6">
                    <div className="text-left">
                      <label htmlFor="email" className="font-semibold">
                        Your Email:
                      </label>
                      <div className="form-icon relative mt-2">
                        <Mail className="absolute start-4 top-3 h-4 w-4" />
                        <input
                          name="email"
                          id="email"
                          type="email"
                          value={email}
                          onChange={e => {
                            setEmail(e.target.value);
                          }}
                          className="form-input ps-11"
                          placeholder="Email :"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1">
                  <div className="mb-5">
                    <div className="text-left">
                      <label htmlFor="comments" className="font-semibold">
                        Your Comment:
                      </label>
                      <div className="form-icon relative mt-2">
                        <MessageCircle className="absolute start-4 top-3 h-4 w-4" />
                        <textarea
                          name="comments"
                          id="comments"
                          value={comments}
                          onChange={e => {
                            setComments(e.target.value);
                          }}
                          className="form-input h-28 ps-11"
                          placeholder="Message :"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  id="submit"
                  name="send"
                  onClick={submitFormHandler}
                  className="btn w-full rounded-md border-blue-600 bg-blue-600 text-white hover:border-blue-700 hover:bg-blue-700">
                  Send Message
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Switcher />
    </>
  );
}
