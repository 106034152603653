import React from 'react';
import { Link as Link2 } from 'react-router-dom';
import LogoLight from '../assets/images/logo-icon-64.png';
import ErrorImage from '../assets/images/error.png';
import Switcher from '../component/Switcher';

export default function Page404() {
  return (
    <>
      <section className="relative bg-blue-600/5">
        <div className="container-fluid relative">
          <div className="grid grid-cols-1">
            <div className="flex min-h-screen flex-col justify-center px-4 py-10 md:px-10">
              <div className="text-center">
                <Link2 to="/">
                  <img src={LogoLight} className="mx-auto" alt="" />
                </Link2>
              </div>
              <div className="title-heading my-auto text-center">
                <img src={ErrorImage} className="mx-auto" alt="" />
                <h1 className="mb-6 mt-3 text-3xl font-bold md:text-4xl">
                  Page Not Found?
                </h1>
                <p className="text-slate-400">
                  Whoops, this is embarassing. <br /> Looks like the page you
                  were looking for wasn't found.
                </p>

                <div className="mt-4">
                  <Link2
                    to="/"
                    className="btn rounded-md border-blue-600 bg-blue-600 text-white hover:border-blue-700 hover:bg-blue-700">
                    Back to Home
                  </Link2>
                </div>
              </div>
              <div className="text-center">
                <p className="mb-0 text-slate-400">
                  © {new Date().getFullYear()} Hously. Design & Develop with{' '}
                  <i className="mdi mdi-heart text-red-600"></i> by{' '}
                  <Link2
                    to="https://shreethemes.in/"
                    target="_blank"
                    className="text-reset">
                    Shreethemes
                  </Link2>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Switcher />
    </>
  );
}
