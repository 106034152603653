import React from 'react';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';

var scroll = Scroll.animateScroll;

export default function Switcher() {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const changeMode = (mode, event) => {
    switch (mode) {
      case 'mode':
        if (document.documentElement.className.includes('dark')) {
          document.documentElement.className = 'light';
        } else {
          document.documentElement.className = 'dark';
        }
        break;
      case 'layout':
        if (event.target?.innerText === 'LTR') {
          document.documentElement.dir = 'ltr';
        } else {
          document.documentElement.dir = 'rtl';
        }
        break;

      default:
        break;
    }
  };

  return (
    <>
      {/* <!-- Switcher --> */}
      <div className="z-3 fixed -left-2 top-1/4">
        <span className="relative inline-block rotate-90">
          <input
            type="checkbox"
            className="checkbox absolute opacity-0"
            id="chk"
            onClick={event => changeMode('mode', event)}
          />
          <label
            className="label flex h-8 w-14 cursor-pointer items-center justify-between rounded-full bg-slate-900 p-1 shadow dark:bg-white dark:shadow-gray-700"
            htmlFor="chk">
            <i className="uil uil-moon text-[20px] text-yellow-500"></i>
            <i className="uil uil-sun text-[20px] text-yellow-500"></i>
            <span className="ball absolute start-[2px] top-[2px] h-7 w-7 rounded-full bg-white dark:bg-slate-900"></span>
          </label>
        </span>
      </div>

      {/* <!-- Switcher --> */}

      {/* <!-- LTR & RTL Mode Code --> */}
      <div className="fixed -left-3 top-[40%] z-50">
        <Link to="#" id="switchRtl">
          <span
            className="relative inline-block -rotate-90 rounded-b-md bg-white px-3 py-1 font-semibold shadow-md ltr:hidden rtl:block dark:bg-slate-900 dark:shadow dark:shadow-gray-800"
            onClick={event => changeMode('layout', event)}>
            LTR
          </span>
          <span
            className="relative inline-block -rotate-90 rounded-b-md bg-white px-3 py-1 font-semibold shadow-md ltr:block rtl:hidden dark:bg-slate-900 dark:shadow dark:shadow-gray-800"
            onClick={event => changeMode('layout', event)}>
            RTL
          </span>
        </Link>
      </div>

      <a
        onClick={scrollToTop}
        id="back-to-top"
        className="back-to-top fixed bottom-5 end-5 z-10 flex hidden h-9 w-9 items-center justify-center rounded-full bg-blue-600 text-center text-lg text-white">
        <i className="uil uil-arrow-up"></i>
      </a>
      {/* <!-- LTR & RTL Mode Code --> */}
    </>
  );
}
