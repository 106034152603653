import React, { useState } from 'react';
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import axios from 'axios';
import ContactImage from '../assets/images/svg/contact.svg';
import Switcher from '../component/Switcher';
import { Hexagon } from 'react-feather';

export default function Contact() {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [question, setQuestion] = useState('');
  const [comments, setComments] = useState('');

  const submitFormHandler = async () => {
    const data = {
      name,
      email,
      question,
      comments,
    };
    try {
      axios
        .post(
          'https://himalayazform-default-rtdb.firebaseio.com/form.json',
          data,
          {
            headers: { 'Content-Type': 'application/json' },
          },
        )
        .then(function (response) {
          console.log('Form data sent successfully.', response);
        })
        .catch(function (error) {
          console.log(error);
        });
      console.log('Form data sent successfully.');
    } catch (error) {
      console.error('Error sending form data:', error);
    }
  };

  return (
    <>
      <Navbar />
      {/* <!-- Google Map --> */}
      {/* <div className="container-fluid relative mt-20">
        <div className="grid grid-cols-1">
          <div className="w-full border-0 leading-[0]">
            <iframe
              title="contact-iframe"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
              style={{ border: '0' }}
              className="h-[500px] w-full"
              allowFullScreen></iframe>
          </div>
        </div>
      </div> */}
      {/* <!-- Google Map --> */}

      {/* <!-- Start Section--> */}
      <section className="relative py-16 lg:py-24">
        <div className="container">
          <div className="grid grid-cols-1 items-center gap-[30px] md:grid-cols-12">
            <div className="md:col-span-6 lg:col-span-7">
              <img src={ContactImage} alt="" />
            </div>

            <div className="md:col-span-6 lg:col-span-5">
              <div className="lg:me-5">
                <div className="rounded-md bg-white p-6 shadow dark:bg-slate-900 dark:shadow-gray-700">
                  <h3 className="mb-6 text-2xl font-medium leading-normal">
                    Get in touch !
                  </h3>

                  <form>
                    <p className="mb-0" id="error-msg"></p>
                    <div id="simple-msg"></div>
                    <div className="grid lg:grid-cols-12 lg:gap-6">
                      <div className="mb-5 lg:col-span-6">
                        <label htmlFor="name" className="font-medium">
                          Name
                        </label>
                        <input
                          id="name"
                          required
                          type="text"
                          className="form-input mt-2"
                          placeholder="enter your full name"
                          onChange={e => setName(e.target.value)}
                        />
                      </div>

                      <div className="mb-5 lg:col-span-6">
                        <label htmlFor="email" className="font-medium">
                          Email
                        </label>
                        <input
                          id="email"
                          required
                          type="email"
                          className="form-input mt-2"
                          placeholder="enter your email"
                          onChange={e => setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-1">
                      <div className="mb-5">
                        <label htmlFor="subject" className="font-medium">
                          Question
                        </label>
                        <input
                          required
                          id="subject"
                          className="form-input mt-2"
                          placeholder="subject"
                          onChange={e => setQuestion(e.target.value)}
                        />
                      </div>

                      <div className="mb-5">
                        <label htmlFor="comments" className="font-medium">
                          Comment
                        </label>
                        <textarea
                          id="comments"
                          className="form-input textarea mt-2"
                          onChange={e => setComments(e.target.value)}
                          placeholder="your comment..."></textarea>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        submitFormHandler();
                      }}
                      className="btn rounded-md bg-blue-600 text-white hover:bg-blue-700">
                      Send Message
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-16 lg:mt-24">
          <div className="grid grid-cols-1 gap-[30px] md:grid-cols-2 lg:grid-cols-3">
            <div className="px-6 text-center">
              <div className="relative -m-3 overflow-hidden text-transparent">
                <Hexagon className="mx-auto h-32 w-32 fill-blue-600/5" />
                <div className="absolute end-0 start-0 top-2/4 mx-auto flex -translate-y-2/4 items-center justify-center rounded-xl align-middle text-4xl text-blue-600 transition-all duration-500 ease-in-out">
                  <i className="uil uil-phone"></i>
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-xl font-medium">Phone</h5>
                {/* <p className="mt-3 text-slate-400">
                  The phrasal sequence of the is now so that many campaign and
                  benefit
                </p> */}

                <div className="mt-5">
                  <a
                    href="tel:+1650288-6687"
                    className="btn btn-link text-blue-600 transition duration-500 after:bg-blue-600 hover:text-blue-600">
                    +1 (650) 288-6687
                  </a>
                </div>
              </div>
            </div>

            <div className="px-6 text-center">
              <div className="relative -m-3 overflow-hidden text-transparent">
                <Hexagon className="mx-auto h-32 w-32 fill-blue-600/5" />
                <div className="absolute end-0 start-0 top-2/4 mx-auto flex -translate-y-2/4 items-center justify-center rounded-xl align-middle text-4xl text-blue-600 transition-all duration-500 ease-in-out">
                  <i className="uil uil-envelope"></i>
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-xl font-medium">Email</h5>
                {/* <p className="mt-3 text-slate-400">
                  The phrasal sequence of the is now so that many campaign and
                  benefit
                </p> */}

                <div className="mt-5">
                  <a
                    href="mailto:info@himalayaz.xyz"
                    className="btn btn-link text-blue-600 transition duration-500 after:bg-blue-600 hover:text-blue-600">
                    info@himalayaz.xyz
                  </a>
                </div>
              </div>
            </div>

            <div className="px-6 text-center">
              <div className="relative -m-3 overflow-hidden text-transparent">
                <Hexagon className="mx-auto h-32 w-32 fill-blue-600/5" />
                <div className="absolute end-0 start-0 top-2/4 mx-auto flex -translate-y-2/4 items-center justify-center rounded-xl align-middle text-4xl text-blue-600 transition-all duration-500 ease-in-out">
                  <i className="uil uil-map-marker"></i>
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-xl font-medium">Location</h5>
                {/* <p className="mt-3 text-slate-400">
                3491, 1603 Capitol Avenue, Suite 413, Cheyenne, WY, Laramie, US, 82001

                </p> */}

                <div className="mt-5">
                  <a
                    href="https://maps.app.goo.gl/zAL74XscewgaTZfE7"
                    data-type="iframe"
                    className="video-play-icon read-more lightbox btn btn-link text-blue-600 transition duration-500 after:bg-blue-600 hover:text-blue-600">
                    View on Google map
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Section--> */}

      <Footer />
      <Switcher />
    </>
  );
}
