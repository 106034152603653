// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDRhjHLNyVVwTjlr7dOjaMAP4k_l1rhiGo',
  authDomain: 'himalayazform.firebaseapp.com',
  databaseURL: 'https://himalayazform-default-rtdb.firebaseio.com',
  projectId: 'himalayazform',
  storageBucket: 'himalayazform.appspot.com',
  messagingSenderId: '911855587719',
  appId: '1:911855587719:web:2f8da534039ac9a3744322',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
