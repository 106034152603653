import React from 'react';
import { Link as Link2 } from 'react-router-dom';
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import BackgroudImage from '../assets/images/himalayaz-small.jpg';
import Switcher from '../component/Switcher';

export default function Privacy() {
  return (
    <>
      <Navbar navClass="navbar-white" />
      {/* <!-- Start Hero --> */}
      <section
        style={{ backgroundImage: `url(${BackgroudImage})` }}
        className="relative table w-full bg-cover bg-center bg-no-repeat py-32 lg:py-36">
        <div className="absolute inset-0 bg-black opacity-80"></div>
        <div className="container">
          <div className="mt-10 grid grid-cols-1 text-center">
            <h3 className="text-3xl font-medium leading-normal text-white md:text-4xl md:leading-normal">
              Privacy Policy
            </h3>
          </div>
        </div>
      </section>
      <div className="relative">
        <div className="shape z-1 overflow-hidden text-white dark:text-slate-900">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"></path>
          </svg>
        </div>
      </div>

      <section className="relative py-16 lg:py-24">
        <div className="container">
          <div className="justify-center md:flex">
            <div className="md:w-3/4">
              <div className="rounded-md bg-white p-6 shadow dark:bg-slate-900 dark:shadow-gray-700">
                <h5 className="mb-4 text-xl font-medium">Privacy Policy :</h5>
                <p className="text-slate-400">
                  This privacy policy sets out how Himalayaz LLC uses and
                  protects any information that you give Himalayaz LLC when you
                  use this website.
                  <br />
                  Himalayaz LLC is committed to ensuring that your privacy is
                  protected. Should we ask you to provide certain information by
                  which you can be identified when using this website, then you
                  can be assured that it will only be used in accordance with
                  this privacy statement.
                  <br />
                  Himalayaz LLC may change this policy from time to time by
                  updating this page. You should check this page from time to
                  time to ensure that you are happy with any changes. This
                  policy is effective from October 1, 2023.
                </p>
                <h5 className="mb-4 mt-8 text-xl font-medium">
                  What we collect :
                </h5>

                <p className="text-slate-400">
                  We may collect the following information:
                  <br />
                  <ul className="list-unstyled mb-4 mt-4 text-slate-400">
                    <li className="mt-2 flex">
                      <i className="uil uil-arrow-right me-2 align-middle text-blue-600"></i>
                      Name
                    </li>
                    <li className="mt-2 flex">
                      <i className="uil uil-arrow-right me-2 align-middle text-blue-600"></i>
                      Contact information including email address
                    </li>
                    <li className="mt-2 flex">
                      <i className="uil uil-arrow-right me-2 align-middle text-blue-600"></i>
                      Demographic information such as postcode, preferences and
                      interests
                    </li>
                    <li className="mt-2 flex">
                      <i className="uil uil-arrow-right me-2 align-middle text-blue-600"></i>
                      Other information relevant to customer surveys and/or
                      offers
                    </li>
                    <li className="mt-2 flex">
                      <i className="uil uil-arrow-right me-2 align-middle text-blue-600"></i>
                      What we do with the information we gather
                    </li>
                    {/* <li className="mt-2 flex">
                    <i className="uil uil-arrow-right me-2 align-middle text-blue-600"></i>
                    Create your own skin to match your brand
                  </li> */}
                  </ul>
                  We require this information to understand your needs and
                  provide you with a better service, and in particular for the
                  following reasons:
                </p>

                <h5 className="mb-4 mt-8 text-xl font-medium">
                  Internal record keeping :
                </h5>
                <p className="text-slate-400">
                  We may use the information to improve our products and
                  services. We may periodically send promotional emails about
                  new products, special offers or other information which we
                  think you may find interesting using the email address which
                  you have provided.
                  <br />
                  From time to time, we may also use your information to contact
                  you for market research purposes. We may contact you by email,
                  phone, fax or mail. We may use the information to customize
                  the website according to your interests.
                </p>

                <h5 className="mb-4 mt-8 text-xl font-medium">Security :</h5>
                <p className="text-slate-400">
                  We are committed to ensuring that your information is secure.
                  In order to prevent unauthorized access or disclosure, we have
                  put in place suitable physical, electronic and managerial
                  procedures to safeguard and secure the information we collect
                  online.
                </p>
                {/* <ul className="list-unstyled text-slate-400 mt-4">
                  <li className="mt-2 flex">
                    <i className="uil uil-arrow-right me-2 align-middle text-blue-600"></i>
                    Digital Marketing Solutions for Tomorrow
                  </li>
                  <li className="mt-2 flex">
                    <i className="uil uil-arrow-right me-2 align-middle text-blue-600"></i>
                    Our Talented & Experienced Marketing Agency
                  </li>
                  <li className="mt-2 flex">
                    <i className="uil uil-arrow-right me-2 align-middle text-blue-600"></i>
                    Create your own skin to match your brand
                  </li>
                  <li className="mt-2 flex">
                    <i className="uil uil-arrow-right me-2 align-middle text-blue-600"></i>
                    Digital Marketing Solutions for Tomorrow
                  </li>
                  <li className="mt-2 flex">
                    <i className="uil uil-arrow-right me-2 align-middle text-blue-600"></i>
                    Our Talented & Experienced Marketing Agency
                  </li>
                  <li className="mt-2 flex">
                    <i className="uil uil-arrow-right me-2 align-middle text-blue-600"></i>
                    Create your own skin to match your brand
                  </li>
                </ul> */}

                <h5 className="mb-4 mt-8 text-xl font-medium">
                  How we use cookies :
                </h5>
                <p className="text-slate-400">
                  A cookie is a small file which asks permission to be placed on
                  your computer's hard drive. Once you agree, the file is added
                  and the cookie helps analyze web traffic or lets you know when
                  you visit a particular site. Cookies allow web applications to
                  respond to you as an individual. The web application can
                  tailor its operations to your needs, likes and dislikes by
                  gathering and remembering information about your preferences.
                  <br />
                  We use traffic log cookies to identify which pages are being
                  used. This helps us analyze data about webpage traffic and
                  improve our website in order to tailor it to customer needs.
                  We only use this information for statistical analysis purposes
                  and then the data is removed from the system.
                  <br />
                  Overall, cookies help us provide you with a better website by
                  enabling us to monitor which pages you find useful and which
                  you do not. A cookie in no way gives us access to your
                  computer or any information about you, other than the data you
                  choose to share with us.
                  <br />
                  You can choose to accept or decline cookies. Most web browsers
                  automatically accept cookies, but you can usually modify your
                  browser setting to decline cookies if you prefer. This may
                  prevent you from taking full advantage of the website.
                </p>

                <h5 className="mb-4 mt-8 text-xl font-medium">
                  Links to other websites :
                </h5>
                <p className="text-slate-400">
                  Our website may contain links to other websites of interest.
                  However, once you have used these links to leave our site, you
                  should note that we do not have any control over that other
                  website. Therefore, we cannot be responsible for the
                  protection and privacy of any information which you provide
                  whilst visiting such sites and such sites are not governed by
                  this privacy statement. You should exercise caution and look
                  at the privacy statement applicable to the website in
                  question.
                </p>

                <h5 className="mb-4 mt-8 text-xl font-medium">
                  Controlling your personal information :
                </h5>
                <p className="text-slate-400">
                  You may choose to restrict the collection or use of your
                  personal information in the following ways:
                  <br />
                  If you have previously agreed to us using your personal
                  information for direct marketing purposes, you may change your
                  mind at any time by writing to or emailing us at
                  info@himalayaz.xyz. We will not sell, distribute or lease your
                  personal information to third parties unless we have your
                  permission or are required by law to do so. You may request
                  details of personal information which we hold about you under
                  the Data Protection Act 1998. A small fee will be payable. If
                  you would like a copy of the information held on you please
                  email info@himalayaz.xyz.
                  <br />
                  If you believe that any information we are holding on you is
                  incorrect or incomplete, please write to or email us as soon
                  as possible at the above address. We will promptly correct any
                  information found to be incorrect.
                </p>

                <h5 className="mb-4 mt-8 text-xl font-medium">Disclaimer :</h5>
                <p className="text-slate-400">
                  The material on our site is given for general information
                  only, and does not constitute professional advice. We make no
                  representations, warranties or guarantees, whether express or
                  implied, that the content on our site is accurate, complete or
                  up-to-date.
                </p>

                <h5 className="mb-4 mt-8 text-xl font-medium">Credit :</h5>
                <p className="text-slate-400">
                  This privacy policy was created using an SEQ Legal template.
                </p>

                <div className="mt-8">
                  <Link2
                    to="#"
                    onClick={() => {
                      window.print();
                    }}
                    className="btn rounded-md bg-blue-600 text-white hover:bg-blue-700">
                    Print
                  </Link2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Terms & Conditions --> */}

      <Footer />
      <Switcher />
    </>
  );
}
