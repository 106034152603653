import React, { useState, useEffect } from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';

import LogoLight from '../assets/images/frame-3.svg';
import LogoDark from '../assets/images/s3.svg';
import { User } from 'react-feather';
import { signOut } from 'firebase/auth';
import { auth } from '../config/firebaseConfig';
import { toast } from 'react-toastify';

export default function Navbar(props) {
  const { navClass, topnavClass } = props;
  const [isOpen, setMenu] = useState(true);
  const [isUser, setIsUser] = useState(false);
  const [userData, setUserDara] = useState(false);
  const navigate = useNavigate();

  window.addEventListener('scroll', windowScroll);

  useEffect(() => {
    if (typeof sessionStorage !== undefined) {
      const NewUserData = JSON.parse(sessionStorage.getItem('UserInfo'));
      NewUserData ? setIsUser(true) : setIsUser(false);
      setUserDara(NewUserData);
    }
  }, [userData]);

  const toggleMenu = () => {
    setMenu(!isOpen);
    if (document.getElementById('navigation')) {
      var elements = document
        .getElementById('navigation')
        .getElementsByTagName('a');
      for (var i = 0, len = elements.length; i < len; i++) {
        elements[i].onclick = function (elem) {
          if (elem.target.getAttribute('href') !== '') {
            if (elem.target.nextElementSibling) {
              var submenu = elem.target.nextElementSibling.nextElementSibling;
              submenu.classList.toggle('open');
            }
          }
        };
      }
    }
  };
  function windowScroll() {
    const navbar = document.getElementById('topnav');
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      if (navbar !== null) {
        navbar?.classList.add('nav-sticky');
      }
    } else {
      if (navbar !== null) {
        navbar?.classList.remove('nav-sticky');
      }
    }

    const mybutton = document.getElementById('back-to-top');
    if (mybutton != null) {
      if (
        document.body.scrollTop > 500 ||
        document.documentElement.scrollTop > 500
      ) {
        mybutton.classList.add('flex');
        mybutton.classList.remove('hidden');
      } else {
        mybutton.classList.add('hidden');
        mybutton.classList.remove('flex');
      }
    }
  }

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        sessionStorage.removeItem('UserInfo');
        toast.success('Sign out successfully', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });

        navigate('/');
        console.log('Signed out successfully');
      })
      .catch(error => {
        toast.error('Something went wrong', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };

  const getClosest = (elem, selector) => {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function (s) {
          var matches = (this.document || this.ownerDocument).querySelectorAll(
              s,
            ),
            i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) {}
          return i > -1;
        };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem;
    }
    return null;
  };

  useEffect(() => {
    var menuItems = document.getElementsByClassName('sub-menu-item');
    if (menuItems) {
      var matchingMenuItem = null;
      for (var idx = 0; idx < menuItems.length; idx++) {
        if (menuItems[idx].href === window.location.href) {
          matchingMenuItem = menuItems[idx];
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add('active');

        var immediateParent = getClosest(matchingMenuItem, 'li');

        if (immediateParent) {
          immediateParent.classList.add('active');
        }

        var parent = getClosest(immediateParent, '.child-menu-item');
        if (parent) {
          parent.classList.add('active');
        }

        var parent = getClosest(parent || immediateParent, '.parent-menu-item');

        if (parent) {
          parent.classList.add('active');

          var parentMenuitem = parent.querySelector('.menu-item');
          if (parentMenuitem) {
            parentMenuitem.classList.add('active');
          }

          var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        } else {
          var parentOfParent = getClosest(
            matchingMenuItem,
            '.parent-parent-menu-item',
          );
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        }
      }
    }

    var elements = document
      .getElementById('navigation')
      .getElementsByTagName('a');
    for (var i = 0, len = elements.length; i < len; i++) {
      elements[i].onclick = function (elem) {
        if (elem.target.getAttribute('href') === '#') {
          var submenu = elem.target.nextElementSibling.nextElementSibling;
          submenu.classList.toggle('open');
        }
      };
    }
  }, []);

  // render() {
  // const toggleClass = this.state.isOpenMenu ? 'hidden' : 'block';

  return (
    <React.Fragment>
      <nav
        id="topnav"
        className={`defaultscroll is-sticky ${topnavClass ? topnavClass : ''}`}>
        <div
          className={`${
            topnavClass !== '' && topnavClass !== undefined
              ? 'container-fluid px-3 md:px-8'
              : 'container'
          }`}>
          {/* <!-- Logo container--> */}
          {navClass === '' || navClass === undefined ? (
            <Link className="logo" to="/">
              <img
                src={LogoDark}
                style={{ width: '40px' }}
                className="inline-block dark:hidden"
                alt=""
              />
              <img
                src={LogoLight}
                style={{ width: '50px' }}
                className="hidden dark:inline-block"
                alt=""
              />
            </Link>
          ) : (
            <Link className="logo" to="#">
              <span className="inline-block dark:hidden">
                <img
                  src={LogoDark}
                  style={{ width: '40px' }}
                  className="l-dark"
                  alt=""
                />
               
              </span>
              <img
                src={LogoLight}
                style={{ width: '50px' }}
                className="hidden dark:inline-block"
                alt=""
              />
            </Link>
          )}
          {/* <!-- End Logo container--> */}

          {/* <!-- Start Mobile Toggle --> */}
          <div className="menu-extras">
            <div className="menu-item">
              <Link
                to="#"
                className="navbar-toggle"
                id="isToggle"
                onClick={toggleMenu}>
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Link>
            </div>
          </div>
          {/* <!-- End Mobile Toggle --> */}

          {/* <!-- Login button Start --> */}
          {!isUser && (
            <ul className="buy-button mb-0 list-none">
              <li className="mb-0 inline">
                <Link
                  to="/auth-login"
                  className="btn btn-icon rounded-full border-blue-600 bg-blue-600 text-white hover:bg-blue-700 dark:border-blue-600">
                  <User className="h-4 w-4 stroke-[3]"></User>
                </Link>
              </li>
              <li className="mb-0 hidden ps-1 sm:inline">
                <Link
                  to="/auth-signup"
                  className="btn rounded-full border-blue-600 bg-blue-600 text-white hover:bg-blue-700 dark:border-blue-600">
                  Signup
                </Link>
              </li>
            </ul>
          )}
          {isUser && (
            <ul className="buy-button mb-0 list-none">
              <li className="mb-0 inline">
                <button
                  onClick={handleSignOut}
                  className="btn  rounded-full border-blue-600 bg-blue-600 text-white hover:bg-blue-700 dark:border-blue-600">
                  Sign out
                </button>
              </li>
            </ul>
          )}
          {/* <!--Login button End--> */}

          <div
            id="navigation"
            className={`${isOpen === true ? 'hidden' : 'block'}`}>
            {/* <!-- Navigation Menu--> */}
            <ul
              className={`navigation-menu  ${
                navClass === '' || navClass === undefined ? '' : 'nav-light'
              }   ${
                topnavClass !== '' && topnavClass !== undefined
                  ? 'justify-center'
                  : 'justify-end'
              }`}>
              <li className="has-submenu parent-menu-item">
                <Link to="/index">Home</Link>
              </li>
              <li>
                <Link to="/aboutus" className="sub-menu-item ">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/Services" className="sub-menu-item">
                  Services
                </Link>
              </li>
              <li>
                <Link to="/faqs" className="sub-menu-item">
                  Faqs
                </Link>
              </li>

              <li>
                <Link to="/blogs" className="sub-menu-item">
                  Blogs
                </Link>
              </li>

              {/* <li className="has-submenu parent-parent-menu-item">
                <Link to="#">Pages</Link>
                <span className="menu-arrow"></span>
                <ul className="submenu">
                  <li>
                    <Link to="/aboutus" className="sub-menu-item">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/Services" className="sub-menu-item">
                      Featues
                    </Link>
                  </li>
                  <li>
                    <Link to="/pricing" className="sub-menu-item">
                      Pricing
                    </Link>
                  </li>
                  <li>
                    <Link to="/faqs" className="sub-menu-item">
                      Faqs
                    </Link>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Link to="#"> Auth Pages </Link>
                    <span className="submenu-arrow"></span>
                    <ul className="submenu">
                      <li>
                        <Link to="/auth-login" className="sub-menu-item">
                          Login
                        </Link>
                      </li>
                      <li>
                        <Link to="/auth-signup" className="sub-menu-item">
                          Signup
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/auth-reset-password"
                          className="sub-menu-item">
                          Reset Password
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Link to="#"> Utility </Link>
                    <span className="submenu-arrow"></span>
                    <ul className="submenu">
                      <li>
                        <Link to="/terms" className="sub-menu-item">
                          Terms of Services
                        </Link>
                      </li>
                      <li>
                        <Link to="/privacy" className="sub-menu-item">
                          Privacy Policy
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Link to="#"> Blog </Link>
                    <span className="submenu-arrow"></span>
                    <ul className="submenu">
                      <li>
                        <Link to="/blogs" className="sub-menu-item">
                          {' '}
                          Blogs
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog-sidebar" className="sub-menu-item">
                          {' '}
                          Blog Sidebar
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog-detail" className="sub-menu-item">
                          {' '}
                          Blog Detail
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Link to="#"> Special </Link>
                    <span className="submenu-arrow"></span>
                    <ul className="submenu">
                      <li>
                        <Link to="/comingsoon" className="sub-menu-item">
                          Comingsoon
                        </Link>
                      </li>
                      <li>
                        <Link to="/maintenance" className="sub-menu-item">
                          Maintenance
                        </Link>
                      </li>
                      <li>
                        <Link to="/404" className="sub-menu-item">
                          404! Error
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li> */}

              <li>
                <Link to="/contact" className="sub-menu-item">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* End Navbar  */}
    </React.Fragment>
  );
}
