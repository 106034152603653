import React from 'react';
import { Link as Link2 } from 'react-router-dom';
import CountUp from 'react-countup';

import Navbar from '../component/Navbar';
import BackgroudImage from '../assets/images/himalayaz-small.jpg';
import About from '../component/About';
import Feature from '../component/Feature';
import ClientTwo from '../component/Client-two';
import Footer from '../component/Footer';
import Switcher from '../component/Switcher';
import { Facebook, Instagram, Linkedin } from 'react-feather';

import Member1 from '../assets/images/client/04.jpg';
import Member2 from '../assets/images/client/05.jpg';
import Member3 from '../assets/images/client/06.jpg';
import Member4 from '../assets/images/client/07.jpg';

export default function Aboutus() {
  return (
    <>
      <Navbar navClass="navbar-white" />
      {/* <!-- Start Hero --> */}
      <section
        style={{ backgroundImage: `url(${BackgroudImage})` }}
        className="relative table w-full bg-cover bg-center bg-no-repeat py-32 lg:py-36">
        <div className="absolute inset-0 bg-black opacity-80"></div>
        <div className="container">
          <div className="mt-10 grid grid-cols-1 text-center">
            <h3 className="text-3xl font-medium leading-normal text-white md:text-4xl md:leading-normal">
              About Us
            </h3>
          </div>
        </div>
      </section>
      <div className="relative">
        <div className="shape z-1 overflow-hidden text-white dark:text-slate-900">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"></path>
          </svg>
        </div>
      </div>
      {/* <!-- End Hero --> */}
      <section className="relative pb-16 md:pb-2">
        <About />
        {/* <Feature /> */}
      </section>
      {/* <!-- Start CTA --> */}
      {/* <section
        style={{ backgroundImage: `url(${BackgroudImage})` }}
        className="relative bg-cover bg-fixed bg-center bg-no-repeat py-24">
        <div className="absolute inset-0 bg-black/60"></div>
        <div className="container">
          <div className="grid grid-cols-1 justify-center text-center md:text-left lg:grid-cols-12">
            <div className="lg:col-span-10 lg:col-start-2">
              <div className="grid grid-cols-1 items-center md:grid-cols-3">
                <div className="counter-box text-center">
                  <h1 className="mb-2 text-4xl font-semibold text-white lg:text-5xl">
                    <CountUp
                      start={1010}
                      className="counter-value"
                      end={1548}
                      duration={2.75}
                    />
                    +
                  </h1>
                  <h5 className="counter-head text-lg font-medium text-white">
                    Properties Sell
                  </h5>
                </div>

                <div className="counter-box text-center">
                  <h1 className="mb-2 text-4xl font-semibold text-white lg:text-5xl">
                    <CountUp
                      start={2}
                      className="counter-value"
                      end={25}
                      duration={2.75}
                    />
                    +
                  </h1>
                  <h5 className="counter-head text-lg font-medium text-white">
                    Award Gained
                  </h5>
                </div>

                <div className="counter-box text-center">
                  <h1 className="mb-2 text-4xl font-semibold text-white lg:text-5xl">
                    <CountUp
                      start={0}
                      className="counter-value"
                      end={9}
                      duration={2.75}
                    />
                    +
                  </h1>
                  <h5 className="counter-head text-lg font-medium text-white">
                    Years Experience
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- End CTA --> */}

      <section className="relative pb-16 md:pb-24">
        {/* <div className="container mt-16 lg:mt-24">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 text-2xl font-semibold leading-normal md:text-3xl md:leading-normal">
              Meet The Agent Team
            </h3>

            <p className="mx-auto max-w-xl text-slate-400">
              Himalayaz LLC: Your Partner in Innovative AI and Software Solutions.
            </p>
          </div>

          <div className="mt-8 grid grid-cols-1 gap-[30px] md:grid-cols-12">
            <div className="md:col-span-6 lg:col-span-3">
              <div className="group text-center">
                <div className="relative mx-auto inline-block h-52 w-52 overflow-hidden rounded-full">
                  <img src={Member1} className="" alt="" />
                  <div className="absolute inset-0 h-52 w-52 rounded-full bg-gradient-to-b from-transparent to-black opacity-0 transition-all duration-500 ease-in-out group-hover:opacity-100"></div>

                  <ul className="absolute -bottom-20 end-0 start-0 list-none transition-all duration-500 ease-in-out group-hover:bottom-5">
                    <li className="ms-1 inline">
                      <Link2
                        to="#"
                        className="btn btn-icon btn-sm rounded-full border border-blue-600 bg-blue-600 text-white hover:border-blue-600 hover:bg-blue-600">
                        <Facebook className="h-4 w-4" />
                      </Link2>
                    </li>
                    <li className="ms-1 inline">
                      <Link2
                        to="#"
                        className="btn btn-icon btn-sm rounded-full border border-blue-600 bg-blue-600 text-white hover:border-blue-600 hover:bg-blue-600">
                        <Instagram className="h-4 w-4" />
                      </Link2>
                    </li>
                    <li className="ms-1 inline">
                      <Link2
                        to="#"
                        className="btn btn-icon btn-sm rounded-full border border-blue-600 bg-blue-600 text-white hover:border-blue-600 hover:bg-blue-600">
                        <Linkedin className="h-4 w-4" />
                      </Link2>
                    </li>
                  </ul>
                </div>

                <div className="content mt-3">
                  <Link2
                    to="#"
                    className="text-xl font-medium transition-all duration-500 ease-in-out hover:text-blue-600">
                    Jack John
                  </Link2>
                  <p className="text-slate-400">Designer</p>
                </div>
              </div>
            </div>

            <div className="md:col-span-6 lg:col-span-3">
              <div className="group text-center">
                <div className="relative mx-auto inline-block h-52 w-52 overflow-hidden rounded-full">
                  <img src={Member2} className="" alt="" />
                  <div className="absolute inset-0 h-52 w-52 rounded-full bg-gradient-to-b from-transparent to-black opacity-0 transition-all duration-500 ease-in-out group-hover:opacity-100"></div>

                  <ul className="absolute -bottom-20 end-0 start-0 list-none transition-all duration-500 ease-in-out group-hover:bottom-5">
                    <li className="ms-1 inline">
                      <Link2
                        to="#"
                        className="btn btn-icon btn-sm rounded-full border border-blue-600 bg-blue-600 text-white hover:border-blue-600 hover:bg-blue-600">
                        <Facebook className="h-4 w-4" />
                      </Link2>
                    </li>
                    <li className="ms-1 inline">
                      <Link2
                        to="#"
                        className="btn btn-icon btn-sm rounded-full border border-blue-600 bg-blue-600 text-white hover:border-blue-600 hover:bg-blue-600">
                        <Instagram className="h-4 w-4" />
                      </Link2>
                    </li>
                    <li className="ms-1 inline">
                      <Link2
                        to="#"
                        className="btn btn-icon btn-sm rounded-full border border-blue-600 bg-blue-600 text-white hover:border-blue-600 hover:bg-blue-600">
                        <Linkedin className="h-4 w-4" />
                      </Link2>
                    </li>
                  </ul>
                </div>

                <div className="content mt-3">
                  <Link2
                    to="#"
                    className="text-xl font-medium transition-all duration-500 ease-in-out hover:text-blue-600">
                    Krista John
                  </Link2>
                  <p className="text-slate-400">Designer</p>
                </div>
              </div>
            </div>

            <div className="md:col-span-6 lg:col-span-3">
              <div className="group text-center">
                <div className="relative mx-auto inline-block h-52 w-52 overflow-hidden rounded-full">
                  <img src={Member3} className="" alt="" />
                  <div className="absolute inset-0 h-52 w-52 rounded-full bg-gradient-to-b from-transparent to-black opacity-0 transition-all duration-500 ease-in-out group-hover:opacity-100"></div>

                  <ul className="absolute -bottom-20 end-0 start-0 list-none transition-all duration-500 ease-in-out group-hover:bottom-5">
                    <li className="ms-1 inline">
                      <Link2
                        to="#"
                        className="btn btn-icon btn-sm rounded-full border border-blue-600 bg-blue-600 text-white hover:border-blue-600 hover:bg-blue-600">
                        <Facebook className="h-4 w-4" />
                      </Link2>
                    </li>
                    <li className="ms-1 inline">
                      <Link2
                        to="#"
                        className="btn btn-icon btn-sm rounded-full border border-blue-600 bg-blue-600 text-white hover:border-blue-600 hover:bg-blue-600">
                        <Instagram className="h-4 w-4" />
                      </Link2>
                    </li>
                    <li className="ms-1 inline">
                      <Link2
                        to="#"
                        className="btn btn-icon btn-sm rounded-full border border-blue-600 bg-blue-600 text-white hover:border-blue-600 hover:bg-blue-600">
                        <Linkedin className="h-4 w-4" />
                      </Link2>
                    </li>
                  </ul>
                </div>

                <div className="content mt-3">
                  <Link2
                    to="#"
                    className="text-xl font-medium transition-all duration-500 ease-in-out hover:text-blue-600">
                    Roger Jackson
                  </Link2>
                  <p className="text-slate-400">Designer</p>
                </div>
              </div>
            </div>

            <div className="md:col-span-6 lg:col-span-3">
              <div className="group text-center">
                <div className="relative mx-auto inline-block h-52 w-52 overflow-hidden rounded-full">
                  <img src={Member4} className="" alt="" />
                  <div className="absolute inset-0 h-52 w-52 rounded-full bg-gradient-to-b from-transparent to-black opacity-0 transition-all duration-500 ease-in-out group-hover:opacity-100"></div>

                  <ul className="absolute -bottom-20 end-0 start-0 list-none transition-all duration-500 ease-in-out group-hover:bottom-5">
                    <li className="ms-1 inline">
                      <Link2
                        to="#"
                        className="btn btn-icon btn-sm rounded-full border border-blue-600 bg-blue-600 text-white hover:border-blue-600 hover:bg-blue-600">
                        <Facebook className="h-4 w-4" />
                      </Link2>
                    </li>
                    <li className="ms-1 inline">
                      <Link2
                        to="#"
                        className="btn btn-icon btn-sm rounded-full border border-blue-600 bg-blue-600 text-white hover:border-blue-600 hover:bg-blue-600">
                        <Instagram className="h-4 w-4" />
                      </Link2>
                    </li>
                    <li className="ms-1 inline">
                      <Link2
                        to="#"
                        className="btn btn-icon btn-sm rounded-full border border-blue-600 bg-blue-600 text-white hover:border-blue-600 hover:bg-blue-600">
                        <Linkedin className="h-4 w-4" />
                      </Link2>
                    </li>
                  </ul>
                </div>

                <div className="content mt-3">
                  <Link2
                    to="#"
                    className="text-xl font-medium transition-all duration-500 ease-in-out hover:text-blue-600">
                    Johnny English
                  </Link2>
                  <p className="text-slate-400">Designer</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <ClientTwo />

        <div className="container mt-16 lg:mt-24">
          <div className="grid grid-cols-1 text-center">
            <h3 className="mb-6 text-2xl font-medium leading-normal text-black dark:text-white md:text-3xl md:leading-normal">
              Have Question ? Get in touch!
            </h3>

            <p className="mx-auto max-w-xl text-slate-400">
              Let's build something amazing together. Reach out now to partner
              with our talented team of technology professionals. We can't wait
              to learn about your business and goals.
            </p>

            <div className="mt-6">
              <Link2
                to="/contact"
                className="btn rounded-md bg-blue-600 text-white hover:bg-blue-700">
                <i className="uil uil-phone me-2 align-middle"></i> Contact us
              </Link2>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Switcher />
    </>
  );
}
