import React, { useState } from 'react';
import { Link as Link2 } from 'react-router-dom';
import Navbar from '../component/Navbar';

import BackgroudImage from '../assets/images/himalayaz-small.jpg';
import Footer from '../component/Footer';
import Switcher from '../component/Switcher';

export default function Terms() {
  const [openSectionIndex, setActiveIndex] = useState(0);

  const sections = [
    {
      title: 'How does it work ?',
      content:
        'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
    },
    {
      title: 'Do I need a designer to use Hously ?',
      content:
        'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
    },
    {
      title: 'What do I need to do to start selling ?',
      content:
        'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
    },
    {
      title: 'What happens when I receive an order ?',
      content:
        'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
    },
  ];

  const handleSectionClick = index => {
    setActiveIndex(state => ({
      openSectionIndex: state.openSectionIndex === index ? -1 : index,
    }));
  };

  return (
    <>
      <Navbar navClass="navbar-white" />
      {/* <!-- Start Hero --> */}
      <section
        style={{ backgroundImage: `url(${BackgroudImage})` }}
        className="relative table w-full bg-cover bg-center bg-no-repeat py-32 lg:py-36">
        <div className="absolute inset-0 bg-black opacity-80"></div>
        <div className="container">
          <div className="mt-10 grid grid-cols-1 text-center">
            <h3 className="text-3xl font-medium leading-normal text-white md:text-4xl md:leading-normal">
              Terms & Conditions
            </h3>
          </div>
        </div>
      </section>
      <div className="relative">
        <div className="shape z-1 overflow-hidden text-white dark:text-slate-900">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"></path>
          </svg>
        </div>
      </div>
      {/* <!-- End Hero --> */}

      {/* <!-- Start Terms & Conditions --> */}
      <section className="relative py-16 lg:py-24">
        <div className="container">
          <div className="justify-center md:flex">
            <div className="md:w-3/4">
              <div className="rounded-md bg-white p-6 shadow dark:bg-slate-900 dark:shadow-gray-700">
                {/* <h5 className="mb-4 text-xl font-medium">Introduction :</h5>
                <p className="text-slate-400">
                  It seems that only fragments of the original text remain in
                  the Lorem Ipsum texts used today. One may speculate that over
                  the course of time certain letters were added or deleted at
                  various positions within the text.
                </p> */}

                <h5 className="mb-4  text-xl font-medium">
                  Terms and Conditions :
                </h5>
                <p className="text-slate-400">
                  These terms and conditions outline the rules and regulations
                  for the use of Himalayaz LLC's Website.
                  <br />
                  <br />
                  By accessing this website we assume you accept these terms and
                  conditions in full. Do not continue to use Himalayaz LLC's
                  website if you do not accept all of the terms and conditions
                  stated on this page. <br />
                  <br />
                  The following terminology applies to these Terms and
                  Conditions, Privacy Statement and Disclaimer Notice and any or
                  all Agreements: "Client", "You" and "Your" refers to you, the
                  person accessing this website and accepting the Company's
                  terms and conditions. "The Company", "Ourselves", "We", "Our"
                  and "Us", refers to our Company. "Party", "Parties", or "Us",
                  refers to both the Client and ourselves, or either the Client
                  or ourselves. All terms refer to the offer, acceptance and
                  consideration of payment necessary to undertake the process of
                  our assistance to the Client in the most appropriate manner,
                  whether by formal meetings of a fixed duration, or any other
                  means, for the express purpose of meeting the Client's needs
                  in respect of provision of the Company's stated
                  services/products, in accordance with and subject to,
                  prevailing law of United States. Any use of the above
                  terminology or other words in the singular, plural,
                  capitalization and/or he/she or they, are taken as
                  interchangeable and therefore as referring to same.
                </p>
                <h5 className="mb-4 mt-8 text-xl font-medium">Cookies :</h5>
                <p className="text-slate-400">
                  We employ the use of cookies. By using Himalayaz LLC's website
                  you consent to the use of cookies in accordance with Himalayaz
                  LLC’s privacy policy. Most of the modern day interactive web
                  sites use cookies to enable us to retrieve user details for
                  each visit. Cookies are used in some areas of our site to
                  enable the functionality of this area and ease of use for
                  those people visiting. Some of our affiliate / advertising
                  partners may also use cookies.
                </p>
                <h5 className="mb-4 mt-8 text-xl font-medium">License :</h5>
                <p className="text-slate-400">
                  Unless otherwise stated, Himalayaz LLC and/or it's licensors
                  own the intellectual property rights for all material on
                  Himalayaz LLC. All intellectual property rights are reserved.
                  You may view and/or print pages from http://www.himalayaz.xyz
                  for your own personal use subject to restrictions set in these
                  terms and conditions.
                </p>
                <h5 className="mb-4 mt-8 text-xl font-medium">
                  You must not :
                </h5>
                <p className="text-slate-400">
                  Republish material from http://www.himalayaz.xyz Sell, rent or
                  sub-license material from http://www.himalayaz.xyz Reproduce,
                  duplicate or copy material from http://www.himalayaz.xyz
                  Redistribute content from Himalayaz LLC (unless content is
                  specifically made for redistribution).
                </p>
                <h5 className="mb-4 mt-8 text-xl font-medium">
                  User Comments :
                </h5>
                <p className="text-slate-400">
                  This Agreement shall begin on the date hereof. Certain parts
                  of this website offer the opportunity for users to post and
                  exchange opinions, information, material and data ('Comments')
                  in areas of the website. Himalayaz LLC does not screen, edit,
                  publish or review Comments prior to their appearance on the
                  website and Comments do not reflect the views or opinions of
                  Himalayaz LLC, its agents or affiliates. Comments reflect the
                  view and opinion of the person who posts such view or opinion.
                  To the extent permitted by applicable laws Himalayaz LLC shall
                  not be responsible or liable for the Comments or for any loss
                  cost, liability, damages or expenses caused and or suffered as
                  a result of any use of and/or posting of and/or appearance of
                  the Comments on this website.
                  <br />
                  Himalayaz LLC reserves the right to monitor all Comments and
                  to remove any Comments which it considers in its absolute
                  discretion to be inappropriate, offensive or otherwise in
                  breach of these Terms and Conditions. You warrant and
                  represent that: You are entitled to post the Comments on our
                  website and have all necessary licenses and consents to do so;
                  <br />
                  The Comments do not infringe any intellectual property right,
                  including without limitation copyright, patent or trademark,
                  or other proprietary right of any third party; The Comments do
                  not contain any defamatory, libelous, offensive, indecent or
                  otherwise unlawful material or material which is an invasion
                  of privacy The Comments will not be used to solicit or promote
                  business or custom or present commercial activities or
                  unlawful activity.
                  <br />
                  You hereby grant to Himalayaz LLC a non-exclusive royalty-free
                  license to use, reproduce, edit and authorize others to use,
                  reproduce and edit any of your Comments in any and all forms,
                  formats or media. Hyperlinking to our Content
                  <br />
                  <br />
                  The following organizations may link to our Web site without
                  prior written approval:
                  <br />
                  Government agencies;
                  <br />
                  Search engines;
                  <br />
                  News organizations;
                  <br />
                  <br />
                  Online directory distributors when they list us in the
                  directory may link to our Web site in the same manner as they
                  hyperlink to the Web sites of other listed businesses; and
                  Systemwide Accredited Businesses except soliciting non-profit
                  organizations, charity shopping malls, and charity fundraising
                  groups which may not hyperlink to our Web site. These
                  organizations may link to our home page, to publications or to
                  other Web site information so long as the link: (a) is not in
                  any way misleading; (b) does not falsely imply sponsorship,
                  endorsement or approval of the linking party and its products
                  or services; and (c) fits within the context of the linking
                  party's site. We may consider and approve in our sole
                  discretion other link requests from the following types of
                  organizations: commonly-known consumer and/or business
                  information sources such as Chambers of Commerce, American
                  Automobile Association, AARP and Consumers Union; dot.com
                  community sites;
                  <br />
                  associations or other groups representing charities, including
                  charity giving sites,
                  <br />
                  online directory distributors;
                  <br />
                  internet portals;
                  <br />
                  accounting, law and consulting firms whose primary clients are
                  businesses; and
                  <br />
                  educational institutions and trade associations.
                  <br />
                  We will approve link requests from these organizations if we
                  determine that: (a) the link would not reflect unfavorably on
                  us or our accredited businesses (for example, trade
                  associations or other organizations representing inherently
                  suspect types of business, such as work-at-home opportunities,
                  shall not be allowed to link); (b)the organization does not
                  have an unsatisfactory record with us; (c) the benefit to us
                  from the visibility associated with the hyperlink outweighs
                  the absence of Himalayaz LLC; and (d) where the link is in the
                  context of general resource information or is otherwise
                  consistent with editorial content in a newsletter or similar
                  product furthering the mission of the organization.
                  <br />
                  These organizations may link to our home page, to publications
                  or to other Web site information so long as the link: (a) is
                  not in any way misleading; (b) does not falsely imply
                  sponsorship, endorsement or approval of the linking party and
                  it products or services; and (c) fits within the context of
                  the linking party's site.
                  <br />
                  If you are among the organizations listed in paragraph 2 above
                  and are interested in linking to our website, you must notify
                  us by sending an e-mail to info@himalayaz.xyz. Please include
                  your name, your organization name, contact information (such
                  as a phone number and/or e-mail address) as well as the URL of
                  your site, a list of any URLs from which you intend to link to
                  our Web site, and a list of the URL(s) on our site to which
                  you would like to link. Allow 2-3 weeks for a response.
                  <br />
                  Approved organizations may hyperlink to our Web site as
                  follows:
                  <br />
                  <br />
                  By use of our corporate name; or
                  <br />
                  By use of the uniform resource locator (Web address) being
                  linked to; or
                  <br />
                  By use of any other description of our Web site or material
                  being linked to that makes sense within the context and format
                  of content on the linking party's site.
                  <br />
                  No use of Himalayaz LLC's logo or other artwork will be
                  allowed for linking absent a trademark license agreement.
                </p>
                <h5 className="mb-4 mt-8 text-xl font-medium">Iframes :</h5>
                <p className="text-slate-400">
                  Without prior approval and express written permission, you may
                  not create frames around our Web pages or use other techniques
                  that alter in any way the visual presentation or appearance of
                  our Web site.
                </p>
                <h5 className="mb-4 mt-8 text-xl font-medium">
                  Content Liability :
                </h5>
                <p className="text-slate-400">
                  We shall have no responsibility or liability for any content
                  appearing on your Web site. You agree to indemnify and defend
                  us against all claims arising out of or based upon your
                  Website. No link(s) may appear on any page on your Web site or
                  within any context containing content or materials that may be
                  interpreted as libelous, obscene or criminal, or which
                  infringes, otherwise violates, or advocates the infringement
                  or other violation of, any third party rights.
                </p>
                <h5 className="mb-4 mt-8 text-xl font-medium">
                  Reservation of Rights :
                </h5>
                <p className="text-slate-400">
                  We reserve the right at any time and in its sole discretion to
                  request that you remove all links or any particular link to
                  our Web site. You agree to immediately remove all links to our
                  Web site upon such request. We also reserve the right to amend
                  these terms and conditions and its linking policy at any time.
                  By continuing to link to our Web site, you agree to be bound
                  to and abide by these linking terms and conditions.
                </p>
                <h5 className="mb-4 mt-8 text-xl font-medium">
                  Removal of links from our website :
                </h5>
                <p className="text-slate-400">
                  If you find any link on our Web site or any linked web site
                  objectionable for any reason, you may contact us about this.
                  We will consider requests to remove links but will have no
                  obligation to do so or to respond directly to you.
                  <br />
                  Whilst we endeavour to ensure that the information on this
                  website is correct, we do not warrant its completeness or
                  accuracy; nor do we commit to ensuring that the website
                  remains available or that the material on the website is kept
                  up to date.
                </p>
                <h5 className="mb-4 mt-8 text-xl font-medium">
                  Content Liability :
                </h5>
                <p className="text-slate-400">
                  We shall have no responsibility or liability for any content
                  appearing on your Web site. You agree to indemnify and defend
                  us against all claims arising out of or based upon your
                  Website. No link(s) may appear on any page on your Web site or
                  within any context containing content or materials that may be
                  interpreted as libelous, obscene or criminal, or which
                  infringes, otherwise violates, or advocates the infringement
                  or other violation of, any third party rights.
                </p>
                <h5 className="mb-4 mt-8 text-xl font-medium">Disclaimer :</h5>
                <p className="text-slate-400">
                  To the maximum extent permitted by applicable law, we exclude
                  all representations, warranties and conditions relating to our
                  website and the use of this website (including, without
                  limitation, any warranties implied by law in respect of
                  satisfactory quality, fitness for purpose and/or the use of
                  reasonable care and skill). Nothing in this disclaimer will:
                  <br />
                  <br />
                  limit or exclude our or your liability for death or personal
                  injury resulting from negligence;
                  <br />
                  limit or exclude our or your liability for fraud or fraudulent
                  misrepresentation;
                  <br />
                  limit any of our or your liabilities in any way that is not
                  permitted under applicable law; or
                  <br />
                  exclude any of our or your liabilities that may not be
                  excluded under applicable law.
                  <br />
                  The limitations and exclusions of liability set out in this
                  Section and elsewhere in this disclaimer: (a) are subject to
                  the preceding paragraph; and (b) govern all liabilities
                  arising under the disclaimer or in relation to the subject
                  matter of this disclaimer, including liabilities arising in
                  contract, in tort (including negligence) and for breach of
                  statutory duty.
                  <br />
                  <br />
                  To the extent that the website and the information and
                  services on the website are provided free of charge, we will
                  not be liable for any loss or damage of any nature.
                </p>

                {/* <h5 className="mb-4 mt-8 text-xl font-medium">
                  Restrictions :
                </h5>
                <p className="text-slate-400">
                  You are specifically restricted from all of the following :
                </p>
                <ul className="mt-3 list-none text-slate-400">
                  <li className="mt-2 flex">
                    <i className="uil uil-arrow-right me-2 align-middle text-blue-600"></i>
                    Digital Marketing Solutions for Tomorrow
                  </li>
                  <li className="mt-2 flex">
                    <i className="uil uil-arrow-right me-2 align-middle text-blue-600"></i>
                    Our Talented & Experienced Marketing Agency
                  </li>
                  <li className="mt-2 flex">
                    <i className="uil uil-arrow-right me-2 align-middle text-blue-600"></i>
                    Create your own skin to match your brand
                  </li>
                  <li className="mt-2 flex">
                    <i className="uil uil-arrow-right me-2 align-middle text-blue-600"></i>
                    Digital Marketing Solutions for Tomorrow
                  </li>
                  <li className="mt-2 flex">
                    <i className="uil uil-arrow-right me-2 align-middle text-blue-600"></i>
                    Our Talented & Experienced Marketing Agency
                  </li>
                  <li className="mt-2 flex">
                    <i className="uil uil-arrow-right me-2 align-middle text-blue-600"></i>
                    Create your own skin to match your brand
                  </li>
                </ul> */}
                {/* 
                <h5 className="mt-8 text-xl font-medium">
                  Users Question & Answer :
                </h5>

                <div
                  id="accordion-collapse"
                  data-accordion="collapse"
                  className="mt-6">
                  {sections.map((section, index) => (
                    <div
                      key={index}
                      className="relative mt-4 overflow-hidden rounded-md shadow dark:shadow-gray-700">
                      <h2
                        className="text-base font-medium"
                        id="accordion-collapse-heading-1">
                        <button
                          type="button"
                          onClick={() => handleSectionClick(index)}
                          className={`flex w-full items-center justify-between p-5 text-left font-medium ${
                            openSectionIndex === index
                              ? 'bg-gray-50 text-blue-600 dark:bg-slate-800'
                              : ''
                          }`}>
                          <span>{section.title}</span>
                          <svg
                            className="h-4 w-4 shrink-0 rotate-180"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              fill-rule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clip-rule="evenodd"></path>
                          </svg>
                        </button>
                      </h2>
                      {openSectionIndex === index && (
                        <div
                          id="accordion-collapse-body-1"
                          aria-labelledby="accordion-collapse-heading-1">
                          <div className="p-5">
                            <p className="text-slate-400 dark:text-gray-400">
                              {' '}
                              {section.content}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                <div className="mt-6">
                  <Link2
                    to="#"
                    className="btn rounded-md bg-blue-600 text-white hover:bg-blue-700">
                    Accept
                  </Link2>
                  <Link2
                    to="#"
                    className="btn ms-2 rounded-md border border-blue-600 bg-transparent text-blue-600 hover:bg-blue-600 hover:text-white">
                    Decline
                  </Link2>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Switcher />
    </>
  );
}
