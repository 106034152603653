import React, { useState } from 'react';
import { Link as Link2 } from 'react-router-dom';
import LogoLight from '../assets/images/frame-3.svg';
import LogoDark from '../assets/images/s3.svg';
import axios from 'axios';

// import LogoLight from "../assets/images/logo-light.png";
import {
  MapPin,
  Mail,
  Phone,
  Dribbble,
  Linkedin,
  Facebook,
  Twitter,
  Instagram,
  ShoppingCart,
} from 'react-feather';

export default function Footer() {
  const [email, setEmail] = useState('');

  const handleNewslatter = () => {
    const data = {
      email,
    };
    try {
      debugger;
      axios
        .post(
          'https://himalayazform-default-rtdb.firebaseio.com/newsletter.json',
          data,
          {
            headers: { 'Content-Type': 'application/json' },
          },
        )
        .then(function (response) {
          debugger;
          console.log('Form data sent successfully.', response);
        })
        .catch(function (error) {
          debugger;
          console.log(error);
        });
      console.log('Form data sent successfully.');
    } catch (error) {
      console.error('Error sending form data:', error);
    }
  };
  return (
    <>
      <footer className="bg-lightBlue-900 relative mt-24 dark:bg-slate-800">
        <div className="container">
          <div className="grid grid-cols-1">
            <div className="relative py-16">
              {/* <!-- Subscribe --> */}
              <div className="relative w-full">
                <div className="relative -top-40 overflow-hidden rounded-xl bg-white px-6 py-10 shadow-lg dark:bg-slate-900 dark:shadow-gray-700 lg:px-8">
                  <div className="grid grid-cols-1 items-center gap-[30px] md:grid-cols-2">
                    <div className="z-1 text-center ltr:md:text-left rtl:md:text-right">
                      <h3 className="text-2xl font-medium leading-normal text-black dark:text-white md:text-3xl md:leading-normal">
                        Subscribe to Newsletter!
                      </h3>
                      <p className="mx-auto max-w-xl text-slate-400 dark:text-white">
                        Stay on top of the latest industry trends, technology
                        tips, and news from our team. Sign up for our monthly
                        newsletter full of actionable insights to give your
                        business a competitive edge..
                      </p>
                    </div>

                    <div className="subcribe-form z-1">
                      <form className="relative max-w-lg md:ms-auto">
                        <input
                          onChange={e => {
                            setEmail(e.target.value);
                          }}
                          type="email"
                          id="subcribe"
                          name="email"
                          className="rounded-full bg-white shadow dark:bg-slate-900 dark:shadow-gray-700"
                          placeholder="Enter your email :"
                        />
                        <button
                          onClick={handleNewslatter}
                          className="btn rounded-full bg-blue-600 text-white hover:bg-blue-700">
                          Subscribe
                        </button>
                      </form>
                    </div>
                  </div>

                  <div className="absolute -start-5 -top-5">
                    <div className="uil uil-envelope text-7xl text-black/5 ltr:-rotate-45 rtl:rotate-45 dark:text-white/5 lg:text-[150px]"></div>
                  </div>

                  <div className="absolute -bottom-5 -end-5">
                    <div className="uil uil-pen text-7xl text-black/5 rtl:-rotate-90 dark:text-white/5 lg:text-[150px]"></div>
                  </div>
                </div>

                <div className="-mt-24 grid grid-cols-1 gap-[30px] md:grid-cols-12">
                  <div className="md:col-span-12 lg:col-span-4">
                    <Link2 to="#" className="text-[22px] focus:outline-none">
                      {/* <img src={LogoLight} alt="" style={{width:'60px'}}/> */}
                      <img
                        src={LogoDark}
                        style={{ width: '70px' }}
                        className="inline-block dark:hidden"
                        alt=""
                      />
                      <img
                        src={LogoLight}
                        style={{ width: '80px' }}
                        className="hidden dark:inline-block"
                        alt=""
                      />
                    </Link2>
                    <p className="mt-6 text-slate-700 dark:text-white">
                      Intelligent software for smarter business.
                    </p>
                  </div>

                  <div className="md:col-span-4 lg:col-span-2">
                    <h5 className="font-semibold tracking-[1px] text-slate-900 dark:text-white">
                      Company
                    </h5>
                    <ul className="footer-list mt-6 list-none">
                      <li>
                        <Link2
                          to="/aboutus"
                          className="text-slate-700 duration-500 ease-in-out hover:text-slate-400 dark:text-white">
                          <i className="uil uil-angle-right-b me-1"></i> About
                          us
                        </Link2>
                      </li>
                      <li className="mt-[10px]">
                        <Link2
                          to="/Services"
                          className="text-slate-700 duration-500 ease-in-out hover:text-slate-400 dark:text-white">
                          <i className="uil uil-angle-right-b me-1"></i>{' '}
                          Services
                        </Link2>
                      </li>

                      <li className="mt-[10px]">
                        <Link2
                          to="/blog"
                          className="text-slate-700 duration-500 ease-in-out hover:text-slate-400 dark:text-white">
                          <i className="uil uil-angle-right-b me-1"></i> Blog
                        </Link2>
                      </li>
                      <li className="mt-[10px]">
                        <Link2
                          to="/auth-login"
                          className="text-slate-700 duration-500 ease-in-out hover:text-slate-400 dark:text-white">
                          <i className="uil uil-angle-right-b me-1"></i> Login
                        </Link2>
                      </li>
                    </ul>
                  </div>

                  <div className="md:col-span-4 lg:col-span-3">
                    <h5 className="font-semibold tracking-[1px] text-slate-900 dark:text-white">
                      Usefull Links
                    </h5>
                    <ul className="footer-list mt-6 list-none">
                      <li>
                        <Link2
                          to="/terms"
                          className="text-slate-700 duration-500 ease-in-out hover:text-slate-400 dark:text-white">
                          <i className="uil uil-angle-right-b me-1"></i> Terms &
                          Conditions
                        </Link2>
                      </li>
                      <li className="mt-[10px]">
                        <Link2
                          to="/privacy"
                          className="text-slate-700 duration-500 ease-in-out hover:text-slate-400 dark:text-white">
                          <i className="uil uil-angle-right-b me-1"></i> Privacy
                          Policy
                        </Link2>
                      </li>

                      <li className="mt-[10px]">
                        <Link2
                          to="/contact"
                          className="text-slate-700 duration-500 ease-in-out hover:text-slate-400 dark:text-white">
                          <i className="uil uil-angle-right-b me-1"></i> Contact
                        </Link2>
                      </li>
                    </ul>
                  </div>

                  <div className="md:col-span-4 lg:col-span-3">
                    <h5 className="font-semibold tracking-[1px] text-slate-900 dark:text-white">
                      Contact Details
                    </h5>
                    <div className="mt-6 flex">
                      <MapPin className="me-3 h-5 w-5 text-blue-600"></MapPin>
                      <div className="w-11/12">
                        <h6 className="mb-2 text-slate-700 dark:text-white">
                          3491, 1603 Capitol Avenue, Suite 413, Cheyenne, WY,
                          Laramie, US, 82001
                        </h6>
                        <a
                          href="https://maps.app.goo.gl/zAL74XscewgaTZfE7"
                          data-type="iframe"
                          className="lightbox  text-slate-900 duration-500 ease-in-out hover:text-blue-900 dark:text-white">
                          View on Google map
                        </a>
                      </div>
                    </div>

                    <div className="mt-6 flex">
                      <Mail className="me-3 h-5 w-5 text-blue-600"></Mail>
                      <div className="">
                        <a
                          href="mailto:info@himalayaz.xyz"
                          className="text-slate-700 duration-500 ease-in-out hover:text-slate-400 dark:text-white">
                          info@himalayaz.xyz
                        </a>
                      </div>
                    </div>

                    <div className="mt-6 flex">
                      <Phone className="me-3 h-5 w-5 text-blue-600"></Phone>
                      <div className="">
                        <a
                          href="tel:+16502886687"
                          className="text-slate-700 duration-500 ease-in-out hover:text-slate-400 dark:text-white">
                          +1 (650) 288-6687
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Subscribe --> */}
            </div>
          </div>
        </div>

        <div className="border-t border-gray-800 px-0 py-[30px] dark:border-gray-700">
          <div className="container text-center">
            <div className="grid items-center gap-6 md:grid-cols-2">
              <div className="text-center ltr:md:text-left rtl:md:text-right">
                <p className="mb-0 text-slate-900 dark:text-white">
                  ©{' '}
                  <script
                    type="text/javascript"
                    id="www-widgetapi-script"
                    src="https://www.youtube.com/s/player/d87d581f/www-widgetapi.vflset/www-widgetapi.js"></script>
                  <script
                    id="iframe_api"
                    src="https://www.youtube.com/iframe_api"></script>
                  {new Date().getFullYear()} Himalayaz LLC.
                  {/* Design & Develop with <i className="mdi mdi-heart text-red-600"></i> by <Link2 to="https://shreethemes.in/" target="_blank" className="text-reset">Himalayaz</Link2>. */}
                </p>
              </div>

              <ul className="list-none text-center ltr:md:text-right rtl:md:text-left">
                <li className="ms-1 inline">
                  <Link2
                    to="https://twitter.com/HimalayazBiz"
                    target="_blank"
                    className="btn btn-icon btn-sm rounded-md border border-gray-900 text-slate-600 hover:border-blue-600 hover:bg-blue-600 hover:text-white dark:border-gray-300 dark:text-white dark:hover:border-blue-600 dark:hover:bg-blue-600">
                    <Twitter className="h-4 w-4"></Twitter>
                  </Link2>
                </li>
                <li className="ms-1 inline">
                  <Link2
                    to="https://www.linkedin.com/in/himalayaz-business-290812258/"
                    target="_blank"
                    className="btn btn-icon btn-sm rounded-md border border-gray-900 text-slate-600 hover:border-blue-600 hover:bg-blue-600 hover:text-white dark:border-gray-300 dark:text-white dark:hover:border-blue-600 dark:hover:bg-blue-600">
                    <Linkedin className="h-4 w-4"></Linkedin>
                  </Link2>
                </li>
                <li className="ms-1 inline">
                  <Link2
                    to="mailto:contact@himalayaz.xyz"
                    className="btn btn-icon btn-sm rounded-md border border-gray-900 text-slate-600 hover:border-blue-600 hover:bg-blue-600 hover:text-white dark:border-gray-300 dark:text-white dark:hover:border-blue-600 dark:hover:bg-blue-600">
                    <Mail className="h-4 w-4"></Mail>
                  </Link2>
                </li>
                {/* <li className="inline ms-1"><Link2 to="https://1.envato.market/hously-react" target="_blank" className="btn btn-icon btn-sm text-gray-400 hover:text-white border border-gray-800 dark:border-gray-900 rounded-md hover:border-blue-600 dark:hover:border-blue-600 hover:bg-blue-600 dark:hover:bg-blue-600"><ShoppingCart className="h-4 w-4"></ShoppingCart></Link2></li> */}
                {/* <li className="inline ms-1"><Link2 to="https://dribbble.com/shreethemes" target="_blank" className="btn btn-icon btn-sm text-gray-400 hover:text-white border border-gray-800 dark:border-gray-700 rounded-md hover:border-blue-600 dark:hover:border-blue-600 hover:bg-blue-600 dark:hover:bg-blue-600"><Dribbble className="h-4 w-4"></Dribbble></Link2></li> */}
                {/* <li className="inline ms-1"><Link2 to="https://www.behance.net/shreethemes" target="_blank" className="btn btn-icon btn-sm text-gray-400 hover:text-white border border-gray-800 dark:border-gray-700 rounded-md hover:border-blue-600 dark:hover:border-blue-600 hover:bg-blue-600 dark:hover:bg-blue-600"><i className="uil uil-behance align-baseline"></i></Link2></li> */}
                {/* <li className="inline ms-1"><Link2 to="https://www.facebook.com/shreethemes" target="_blank" className="btn btn-icon btn-sm text-gray-400 hover:text-white border border-gray-800 dark:border-gray-700 rounded-md hover:border-blue-600 dark:hover:border-blue-600 hover:bg-blue-600 dark:hover:bg-blue-600"><Facebook className="h-4 w-4"></Facebook></Link2></li> */}
                {/* <li className="inline ms-1"><Link2 to="https://www.instagram.com/shreethemes/" target="_blank" className="btn btn-icon btn-sm text-gray-400 hover:text-white border border-gray-800 dark:border-gray-700 rounded-md hover:border-blue-600 dark:hover:border-blue-600 hover:bg-blue-600 dark:hover:bg-blue-600"><Instagram className="h-4 w-4"></Instagram></Link2></li> */}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
