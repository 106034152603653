import React from 'react';
import Select from 'react-select';
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';

import properties from '../config/grid-data';
import PaginationTwo from '../component/Pagination-two';
import Switcher from '../component/Switcher';

const Houses = [
  { value: 'AF', label: 'Apartment' },
  { value: 'AZ', label: ' Offices' },
  { value: 'BS', label: 'Townhome' },
];
const minPrice = [
  { value: '1', label: '500' },
  { value: '2', label: '1000' },
  { value: '3', label: '2000' },
  { value: '4', label: '3000' },
  { value: '5', label: '4000' },
  { value: '5', label: '5000' },
  { value: '5', label: '6000' },
];
const maxPrice = [
  { value: '1', label: '500' },
  { value: '2', label: '1000' },
  { value: '3', label: '2000' },
  { value: '4', label: '3000' },
  { value: '5', label: '4000' },
  { value: '5', label: '5000' },
  { value: '5', label: '6000' },
];

export default function List() {
  return (
    <>
      <Navbar navClass="navbar-white" />
      <section className="relative table w-full bg-[url('../../assets/images/bg/01.jpg')] bg-cover bg-center bg-no-repeat py-32 lg:py-36">
        <div className="absolute inset-0 bg-black opacity-80"></div>
        <div className="container">
          <div className="mt-10 grid grid-cols-1 text-center">
            <h3 className="text-3xl font-medium leading-normal text-white md:text-4xl md:leading-normal">
              List View Layout
            </h3>
          </div>
        </div>
      </section>
      <div className="relative">
        <div className="shape z-1 overflow-hidden text-white dark:text-slate-900">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"></path>
          </svg>
        </div>
      </div>
      <div className="z-1 container relative -mt-16">
        <div className="grid grid-cols-1">
          <form className="rounded-xl bg-white p-6 shadow-md dark:bg-slate-900 dark:shadow-gray-700">
            <div className="registration-form text-dark text-start">
              <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 lg:gap-0">
                <div>
                  <label className="form-label font-medium text-slate-900 dark:text-white">
                    Search : <span className="text-red-600">*</span>
                  </label>
                  <div className="filter-search-form filter-border relative mt-2">
                    <i className="uil uil-search icons"></i>
                    <input
                      name="name"
                      type="text"
                      id="job-keyword"
                      className="form-input filter-input-box border-0 bg-gray-50 dark:bg-slate-800"
                      placeholder="Search your Keywords"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="buy-properties"
                    className="form-label font-medium text-slate-900 dark:text-white">
                    Select Categories:
                  </label>
                  <div className="filter-search-form filter-border relative mt-2">
                    <i className="uil uil-estate icons"></i>
                    <Select
                      className="form-input filter-input-box border-0 bg-gray-50 dark:bg-slate-800"
                      options={Houses}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="buy-min-price"
                    className="form-label font-medium text-slate-900 dark:text-white">
                    Min Price :
                  </label>
                  <div className="filter-search-form filter-border relative mt-2">
                    <i className="uil uil-usd-circle icons"></i>
                    <Select
                      className="form-input filter-input-box border-0 bg-gray-50 dark:bg-slate-800"
                      options={minPrice}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="buy-max-price"
                    className="form-label font-medium text-slate-900 dark:text-white">
                    Max Price :
                  </label>
                  <div className="filter-search-form relative mt-2">
                    <i className="uil uil-usd-circle icons"></i>
                    <Select
                      className="form-input filter-input-box border-0 bg-gray-50 dark:bg-slate-800"
                      options={maxPrice}
                    />
                  </div>
                </div>

                <div className="lg:mt-6">
                  <input
                    type="submit"
                    id="search-buy"
                    name="search"
                    className="btn searchbtn submit-btn !h-12 w-full rounded border-blue-600 bg-blue-600 text-white hover:border-blue-700 hover:bg-blue-700"
                    value="Search"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <section className="relative py-16 lg:py-24">
        <div className="container">
          <PaginationTwo
            itemsPerPage={6}
            items={properties}
            gridClass={`grid lg:grid-cols-2 grid-cols-1 gap-[30px]`}
          />
        </div>
      </section>
      {/* <!-- End --> */}
      <Footer />
      <Switcher />
    </>
  );
}
