import React from 'react';
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';

import properties from '../config/grid-data';
import Pagination from '../component/Pagination';
import Switcher from '../component/Switcher';

export default function GridSidebar() {
  return (
    <>
      <Navbar navClass="navbar-white" />
      <section className="relative table w-full bg-[url('../../assets/images/bg/01.jpg')] bg-cover bg-center bg-no-repeat py-32 lg:py-36">
        <div className="absolute inset-0 bg-black opacity-80"></div>
        <div className="container">
          <div className="mt-10 grid grid-cols-1 text-center">
            <h3 className="text-3xl font-medium leading-normal text-white md:text-4xl md:leading-normal">
              Grid View Layout
            </h3>
          </div>
        </div>
      </section>
      <div className="relative">
        <div className="shape z-1 overflow-hidden text-white dark:text-slate-900">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"></path>
          </svg>
        </div>
      </div>

      <section className="relative py-16 lg:py-24">
        <div className="container">
          <div className="grid grid-cols-1 gap-[30px] md:grid-cols-12">
            <div className="md:col-span-6 lg:col-span-4">
              <div className="sticky top-20 rounded-xl bg-white p-6 shadow dark:bg-slate-900 dark:shadow-gray-700">
                <form>
                  <div className="grid grid-cols-1 gap-3">
                    <div>
                      <label htmlFor="searchname" className="font-medium">
                        Search Properties
                      </label>
                      <div className="relative mt-2">
                        <i className="uil uil-search absolute start-3 top-[8px] text-lg"></i>
                        <input
                          name="search"
                          id="searchname"
                          type="text"
                          className="form-input border border-slate-100 ps-10 dark:border-slate-800"
                          placeholder="Search"
                        />
                      </div>
                    </div>

                    <div>
                      <label className="font-medium">Categories</label>
                      <select className="form-select form-input mt-1 block w-full border border-slate-100 dark:border-slate-800">
                        <option value="re">Residential</option>
                        <option value="la">Land</option>
                        <option value="co">Commercial</option>
                        <option value="ind">Industrial</option>
                        <option value="inv">Investment</option>
                      </select>
                    </div>

                    <div>
                      <label className="font-medium">Location</label>
                      <select className="form-select form-input mt-1 block w-full border border-slate-100 dark:border-slate-800">
                        <option value="NY">New York</option>
                        <option value="MC">North Carolina</option>
                        <option value="SC">South Carolina</option>
                      </select>
                    </div>

                    <div>
                      <input
                        type="submit"
                        className="btn w-full rounded-md border-blue-600 bg-blue-600 text-white hover:border-blue-700 hover:bg-blue-700"
                        value="Apply Filter"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="md:col-span-6 lg:col-span-8">
              <Pagination
                itemsPerPage={6}
                items={properties}
                gridClass={`grid lg:grid-cols-2 grid-cols-1 gap-[30px]`}
              />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End --> */}
      <Footer />
      <Switcher />
    </>
  );
}
