import React from 'react';
import { Link as Link2 } from 'react-router-dom';
import Navbar from '../component/Navbar';
import BackgroudImage from '../assets/images/himalayaz-small.jpg';
import Poperty2Image from '../assets/images/AI_generated.jpg';
import Poperty1Image from '../assets/images/content_base.jpg';
import Poperty3Image from '../assets/images/blog-3.jpg';
import Poperty4Image from '../assets/images/property/4.jpg';
import Poperty5Image from '../assets/images/property/5.jpg';
import Poperty6Image from '../assets/images/property/6.jpg';
import Footer from '../component/Footer';
import Switcher from '../component/Switcher';

export default function Blogs() {
  const blogList = [
    {
      title: 'The Future of the Content-Based Economy',
      date: 'August 16, 2023',
      type: 'Himalayaz LLC',
      image: Poperty1Image,
      detailLink: '/blog-detail-2',
    },
    {
      title: 'The Future of NoCode and AI-Based Systems',
      date: 'September 16, 2023',
      type: 'Himalayaz LLC',
      image: Poperty2Image,
      detailLink: '/blog-detail-1',
    },
    {
      title:
        'Helping You Achieve Net Zero with Green Software Development Solutions',
      date: 'September 16, 2023',
      type: 'Himalayaz LLC',
      image: Poperty3Image,
      detailLink: '/blog-detail-3',
    },
  ];

  return (
    <>
      <Navbar navClass="navbar-white" />
      {/* <!-- Start Hero --> */}
      <section
        style={{ backgroundImage: `url(${BackgroudImage})` }}
        className="relative table w-full  bg-cover bg-center bg-no-repeat py-32 lg:py-36">
        <div className="absolute inset-0 bg-black opacity-80"></div>
        <div className="container">
          <div className="mt-10 grid grid-cols-1 text-center">
            <h3 className="text-3xl font-medium leading-normal text-white md:text-4xl md:leading-normal">
              Blogs & News
            </h3>
          </div>
        </div>
      </section>
      <div className="relative">
        <div className="shape z-1 overflow-hidden text-white dark:text-slate-900">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"></path>
          </svg>
        </div>
      </div>
      {/* <!-- End Hero --> */}

      {/* <!-- Start --> */}

      <section className="relative py-16 md:py-24">
        <div className="container">
          <div className="grid grid-cols-1 gap-[30px] md:grid-cols-2 lg:grid-cols-3">
            {blogList.map((item, index) => (
              <div
                key={index}
                className="group relative h-fit overflow-hidden rounded-xl bg-white shadow transition-all duration-500 hover:-mt-[5px] dark:bg-slate-900 dark:shadow-gray-700">
                <div className="relative overflow-hidden">
                  <img src={item.image} className="" alt="" />
                  <div className="absolute end-4 top-4">
                    <span className="h-5 rounded-full bg-blue-600 px-2.5 py-2 text-[14px] font-medium text-white">
                      {item.type}
                    </span>
                  </div>
                </div>

                <div className="relative p-6">
                  <div className="">
                    <div className="mb-4 flex justify-between">
                      <span className="text-sm text-slate-400">
                        <i className="uil uil-calendar-alt me-2 text-slate-900 dark:text-white"></i>
                        {item.date}
                      </span>
                      <span className="ms-3 text-sm text-slate-400">
                        <i className="uil uil-clock me-2 text-slate-900 dark:text-white"></i>
                        5 min read
                      </span>
                    </div>

                    <Link2
                      to="#"
                      className="title btn-link text-xl font-medium duration-500 ease-in-out hover:text-blue-600">
                      {item.title}
                    </Link2>

                    <div className="mt-3">
                      <Link2
                        to={item.detailLink}
                        className="btn btn-link duration-500 ease-in-out after:bg-blue-600 hover:text-blue-600">
                        Read More <i className="uil uil-arrow-right"></i>
                      </Link2>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Switcher />
      <Footer />
    </>
  );
}
