import React from 'react';
import { Link as Link2 } from 'react-router-dom';
import Navbar from '../component/Navbar';
import BackgroudImage from '../assets/images/himalayaz-small.jpg';
import { Hexagon } from 'react-feather';
import Client from '../component/Client';
import Footer from '../component/Footer';
import Switcher from '../component/Switcher';
export default function Services() {
  return (
    <>
      <Navbar navClass="navbar-white" />
      {/* <!-- Start Hero --> */}
      <section
        style={{ backgroundImage: `url(${BackgroudImage})` }}
        className="relative table w-full bg-cover bg-center bg-no-repeat py-32 lg:py-36">
        <div className="absolute inset-0 bg-black opacity-80"></div>
        <div className="container">
          <div className="mt-10 grid grid-cols-1 text-center">
            <h3 className="text-3xl font-medium leading-normal text-white md:text-4xl md:leading-normal">
              Services
            </h3>
          </div>
        </div>
      </section>
      <div className="relative">
        <div className="shape z-1 overflow-hidden text-white dark:text-slate-900">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"></path>
          </svg>
        </div>
      </div>
      {/* <!-- End Hero --> */}

      <section className="relative py-16 lg:py-24">
        <div className="container">
          <div className="grid grid-cols-1 gap-x-[30px] gap-y-[50px] md:grid-cols-2 lg:grid-cols-3">
            <div className="group relative overflow-hidden rounded-xl bg-white transition-all duration-500 ease-in-out dark:bg-slate-900 lg:px-10">
              <div className="relative -m-3 overflow-hidden text-transparent">
                <Hexagon className="h-32 w-32 fill-blue-600/5"></Hexagon>
                <div className="absolute start-[45px] top-[50%] flex -translate-y-[50%] items-center justify-center rounded-xl align-middle text-4xl text-blue-600 transition-all duration-500 ease-in-out">
                  <i className="mdi mdi-kickstarter"></i>
                </div>
              </div>

              <div className="mt-6">
                <Link2
                  to="#"
                  className="text-xl font-medium hover:text-blue-600">
                  Kickstart Software
                </Link2>
                <p className="mt-3 text-slate-400">
                  Himalayaz LLC specializes in providing technical setup and
                  infrastructure for early stage startups. We handle all aspects
                  of building a scalable and secure technical foundation
                  including cloud hosting, databases, security, backups, DevOps
                  pipelines, and more. Our dedicated engineers have launched
                  hundreds of successful startups. Let us design and implement
                  the right tech stack to accelerate your business.
                </p>
              </div>
            </div>

            <div className="group relative overflow-hidden rounded-xl bg-white transition-all duration-500 ease-in-out dark:bg-slate-900 lg:px-10">
              <div className="relative -m-3 overflow-hidden text-transparent">
                <Hexagon className="h-32 w-32 fill-blue-600/5"></Hexagon>
                <div className="absolute start-[45px] top-[50%] flex -translate-y-[50%] items-center justify-center rounded-xl align-middle text-4xl text-blue-600 transition-all duration-500 ease-in-out">
                  <i className="mdi mdi-shield-sun"></i>
                </div>
              </div>

              <div className="mt-6">
                <Link2
                  to="#"
                  className="text-xl font-medium hover:text-blue-600">
                  Fractional CTOs
                </Link2>
                <p className="mt-3 text-slate-400">
                  Does your startup need strategic technical leadership but
                  can't afford a fulltime CTO? Himalayaz LLC provides
                  experienced fractional CTOs to guide your product and
                  engineering teams. Our fractional CTOs have decades of
                  experience leading tech teams at hypergrowth startups. We work
                  closely with you to architect systems, hire talent, implement
                  best practices, and make critical technology decisions. Ramp
                  up your tech capabilities without breaking the bank.
                </p>
              </div>
            </div>

            <div className="group relative overflow-hidden rounded-xl bg-white transition-all duration-500 ease-in-out dark:bg-slate-900 lg:px-10">
              <div className="relative -m-3 overflow-hidden text-transparent">
                <Hexagon className="h-32 w-32 fill-blue-600/5"></Hexagon>
                <div className="absolute start-[45px] top-[50%] flex -translate-y-[50%] items-center justify-center rounded-xl align-middle text-4xl text-blue-600 transition-all duration-500 ease-in-out">
                  <i className="mdi mdi-robot-mower"></i>
                </div>
              </div>

              <div className="mt-6">
                <Link2
                  to="#"
                  className="text-xl font-medium hover:text-blue-600">
                  Artificial Intelligence
                </Link2>
                <p className="mt-3 text-slate-400">
                  Harness the power of AI and machine learning to extract
                  insights, automate processes, and delight customers. Himalayaz
                  LLC builds custom AI solutions including chatbots,
                  recommendation engines, predictive analytics, computer vision,
                  natural language processing, and more. Our AI solutions
                  integrate seamlessly with your tech stack to drive impact
                  across your organization. Let our PhDs in machine learning
                  bring the future to your business today.
                </p>
              </div>
            </div>

            <div className="group relative overflow-hidden rounded-xl bg-white transition-all duration-500 ease-in-out dark:bg-slate-900 lg:px-10">
              <div className="relative -m-3 overflow-hidden text-transparent">
                <Hexagon className="h-32 w-32 fill-blue-600/5"></Hexagon>
                <div className="absolute start-[45px] top-[50%] flex -translate-y-[50%] items-center justify-center rounded-xl align-middle text-4xl text-blue-600 transition-all duration-500 ease-in-out">
                  <i className="mdi mdi-security"></i>
                </div>
              </div>

              <div className="mt-6">
                <Link2
                  to="#"
                  className="text-xl font-medium hover:text-blue-600">
                  Security
                </Link2>
                <p className="mt-3 text-slate-400">
                  Security is foundational for any digital business. Himalayaz
                  LLC applies zero trust principles, defense in depth, and
                  advanced threat modeling to secure your entire tech ecosystem.
                  We perform security audits, implement controls, monitor
                  threats, and ensure regulatory compliance so you can focus on
                  your core business. Partner with us to protect your company,
                  customers, and reputation.
                </p>
              </div>
            </div>

            <div className="group relative overflow-hidden rounded-xl bg-white transition-all duration-500 ease-in-out dark:bg-slate-900 lg:px-10">
              <div className="relative -m-3 overflow-hidden text-transparent">
                <Hexagon className="h-32 w-32 fill-blue-600/5"></Hexagon>
                <div className="absolute start-[45px] top-[50%] flex -translate-y-[50%] items-center justify-center rounded-xl align-middle text-4xl text-blue-600 transition-all duration-500 ease-in-out">
                  <i className="mdi mdi-electron-framework"></i>
                </div>
              </div>

              <div className="mt-6">
                <Link2
                  to="#"
                  className="text-xl font-medium hover:text-blue-600">
                  Software Infrastructure
                </Link2>
                <p className="mt-3 text-slate-400">
                  Robust infrastructure unleashes developer productivity and app
                  performance. Himalayaz LLC specializes in building resilient,
                  scalable infrastructure on premise or in the cloud. We design,
                  deploy, and manage solutions including Kubernetes,
                  microservices, CI/CD pipelines, observability, testing, cloud
                  architecture, and more. Modernize your foundations for
                  stability, speed, and savings.
                </p>
              </div>
            </div>

            <div className="group relative overflow-hidden rounded-xl bg-white transition-all duration-500 ease-in-out dark:bg-slate-900 lg:px-10">
              <div className="relative -m-3 overflow-hidden text-transparent">
                <Hexagon className="h-32 w-32 fill-blue-600/5"></Hexagon>
                <div className="absolute start-[45px] top-[50%] flex -translate-y-[50%] items-center justify-center rounded-xl align-middle text-4xl text-blue-600 transition-all duration-500 ease-in-out">
                  <i className="mdi mdi-code-tags"></i>
                </div>
              </div>

              <div className="mt-6">
                <Link2
                  to="#"
                  className="text-xl font-medium hover:text-blue-600">
                  Software Development
                </Link2>
                <p className="mt-3 text-slate-400">
                  Innovative software creates competitive advantage. Himalayaz
                  LLC builds custom web and mobile apps to bring your ideas to
                  life. Our expert developers and designers collaborate closely
                  with you to turn concepts into shipped products. We handle
                  frontend, backend, APIs, databases, DevOps, QA, UX, and more.
                  Let us help you delight customers with software that solves
                  real problems.
                </p>
              </div>
            </div>

            <div className="group relative overflow-hidden rounded-xl bg-white transition-all duration-500 ease-in-out dark:bg-slate-900 lg:px-10">
              <div className="relative -m-3 overflow-hidden text-transparent">
                <Hexagon className="h-32 w-32 fill-blue-600/5"></Hexagon>
                <div className="absolute start-[45px] top-[50%] flex -translate-y-[50%] items-center justify-center rounded-xl align-middle text-4xl text-blue-600 transition-all duration-500 ease-in-out">
                  <i className="mdi mdi-robot-vacuum"></i>
                </div>
              </div>

              <div className="mt-6">
                <Link2
                  to="#"
                  className="text-xl font-medium hover:text-blue-600">
                  Automated SaaS Development
                </Link2>
                <p className="mt-3 text-slate-400">
                  Launch your SaaS faster with Himalayaz LLC. Our no-code
                  platforms and automation tools accelerate building, testing,
                  and scaling multi-tenant SaaS applications. We handle tedious
                  tasks like user management, payments, APIs, infrastructure,
                  compliance, analytics, and more so you can focus on core
                  features. Get your SaaS to market in weeks instead of years.{' '}
                </p>
              </div>
            </div>

            <div className="group relative overflow-hidden rounded-xl bg-white transition-all duration-500 ease-in-out dark:bg-slate-900 lg:px-10">
              <div className="relative -m-3 overflow-hidden text-transparent">
                <Hexagon className="h-32 w-32 fill-blue-600/5"></Hexagon>
                <div className="absolute start-[45px] top-[50%] flex -translate-y-[50%] items-center justify-center rounded-xl align-middle text-4xl text-blue-600 transition-all duration-500 ease-in-out">
                  <i className="mdi mdi-face-agent"></i>
                </div>
              </div>

              <div className="mt-6">
                <Link2
                  to="#"
                  className="text-xl font-medium hover:text-blue-600">
                  Technical Support
                </Link2>
                <p className="mt-3 text-slate-400">
                  Deliver amazing technical support experiences using Himalayaz
                  LLC's advanced platforms and global talent network. Our tools
                  include ticket tracking, remote access, diagnostics, knowledge
                  bases, chatbots, and more. Our experienced technical support
                  specialists resolve issues quickly and delight customers. Give
                  your customers the tech support they deserve.
                </p>
              </div>
            </div>

            <div className="group relative overflow-hidden rounded-xl bg-white transition-all duration-500 ease-in-out dark:bg-slate-900 lg:px-10">
              <div className="relative -m-3 overflow-hidden text-transparent">
                <Hexagon className="h-32 w-32 fill-blue-600/5"></Hexagon>
                <div className="absolute start-[45px] top-[50%] flex -translate-y-[50%] items-center justify-center rounded-xl align-middle text-4xl text-blue-600 transition-all duration-500 ease-in-out">
                  <i className="mdi mdi-magnify-scan"></i>
                </div>
              </div>

              <div className="mt-6">
                <Link2
                  to="#"
                  className="text-xl font-medium hover:text-blue-600">
                  Software Quality Assurance
                </Link2>
                <p className="mt-3 text-slate-400">
                  Bulletproof software requires rigorous quality assurance.
                  Himalayaz LLC performs manual and automated testing to
                  identify defects before they impact customers. Our QA experts
                  design test plans, build test automation, perform exploratory
                  testing, and verify bug fixes during the development process.
                  Ship with confidence knowing Himalayaz LLC has your back.
                </p>
              </div>
            </div>

            <div className="group relative overflow-hidden rounded-xl bg-white transition-all duration-500 ease-in-out dark:bg-slate-900 lg:px-10">
              <div className="relative -m-3 overflow-hidden text-transparent">
                <Hexagon className="h-32 w-32 fill-blue-600/5"></Hexagon>
                <div className="absolute start-[45px] top-[50%] flex -translate-y-[50%] items-center justify-center rounded-xl align-middle text-4xl text-blue-600 transition-all duration-500 ease-in-out">
                  <i className="mdi mdi-monitor-multiple"></i>
                </div>
              </div>

              <div className="mt-6">
                <Link2
                  to="#"
                  className="text-xl font-medium hover:text-blue-600">
                  User Experience
                </Link2>
                <p className="mt-3 text-slate-400">
                  Great UX transforms ordinary products into delightful
                  experiences. Himalayaz LLC conducts UX research, creates user
                  journeys, prototypes interactions, and builds pixel-perfect
                  interfaces. Our Human Computer Interaction (HCI) experts
                  design intuitive, inclusive experiences optimized for your
                  users and business goals. Let us make your product
                  irresistible.
                </p>
              </div>
            </div>

            <div className="group relative overflow-hidden rounded-xl bg-white transition-all duration-500 ease-in-out dark:bg-slate-900 lg:px-10">
              <div className="relative -m-3 overflow-hidden text-transparent">
                <Hexagon className="h-32 w-32 fill-blue-600/5"></Hexagon>
                <div className="absolute start-[45px] top-[50%] flex -translate-y-[50%] items-center justify-center rounded-xl align-middle text-4xl text-blue-600 transition-all duration-500 ease-in-out">
                  <i className="mdi mdi-cards-heart"></i>
                </div>
              </div>

              <div className="mt-6">
                <Link2
                  to="#"
                  className="text-xl font-medium hover:text-blue-600">
                  Social Media Marketing
                </Link2>
                <p className="mt-3 text-slate-400">
                  Acquire and engage more customers using Himalayaz LLC's social
                  media marketing superpowers. Our data-driven social campaigns
                  build awareness, drive conversions, and grow loyal fans across
                  platforms like Facebook, Instagram, Twitter, LinkedIn,
                  Pinterest, and TikTok. We help develop your brand personality
                  and content strategy. Let our certified professionals expand
                  your social influence.
                </p>
              </div>
            </div>

            <div className="group relative overflow-hidden rounded-xl bg-white transition-all duration-500 ease-in-out dark:bg-slate-900 lg:px-10">
              <div className="relative -m-3 overflow-hidden text-transparent">
                <Hexagon className="h-32 w-32 fill-blue-600/5"></Hexagon>
                <div className="absolute start-[45px] top-[50%] flex -translate-y-[50%] items-center justify-center rounded-xl align-middle text-4xl text-blue-600 transition-all duration-500 ease-in-out">
                  <i className="mdi mdi-globe-model"></i>
                </div>
              </div>

              <div className="mt-6">
                <Link2
                  to="#"
                  className="text-xl font-medium hover:text-blue-600">
                  Global Talent Pool
                </Link2>
                <p className="mt-3 text-slate-400">
                  Access the world's best technical and marketing talent through
                  Himalayaz LLC's global talent network. We hand-pick skilled
                  professionals who meet our rigorous quality standards and
                  understand your business needs. Our flexible engagement models
                  - freelance, project, part time, fully managed teams - scale
                  to your needs. Work with exceptional talent without
                  geographic constraints.{' '}
                </p>
              </div>
            </div>
          </div>
        </div>

        <Client />

        <div className="container mt-16 lg:mt-24">
          <div className="grid grid-cols-1 text-center">
            <h3 className="mb-6 text-2xl font-medium leading-normal text-black dark:text-white md:text-3xl md:leading-normal">
              Have Question ? Get in touch!
            </h3>

            <p className="mx-auto max-w-xl text-slate-400">
              Let's build something amazing together. Reach out now to partner
              with our talented team of technology professionals. We can't wait
              to learn about your business and goal.
            </p>

            <div className="mt-6">
              <Link2
                to="/contact"
                className="btn rounded-md bg-blue-600 text-white hover:bg-blue-700">
                <i className="uil uil-phone me-2 align-middle"></i> Contact us
              </Link2>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Switcher />
    </>
  );
}
