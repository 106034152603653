import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function PaginationTwo({ itemsPerPage, items, gridClass }) {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageClick = event => {
    const newPage = Number(event.target.value);
    setCurrentPage(newPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = items.slice(startIndex, endIndex);
  const numPages = Math.ceil(items.length / itemsPerPage);

  const pageButtons = Array.from({ length: numPages }, (_, i) => i + 1).map(
    pageNum => (
      <button
        className="mx-1 inline-flex h-10 w-10 items-center justify-center rounded-full bg-white text-slate-400 shadow-sm hover:border-blue-600 hover:bg-blue-600 hover:text-white dark:bg-slate-900 dark:shadow-gray-700 dark:hover:border-blue-600 dark:hover:bg-blue-600"
        key={pageNum}
        value={pageNum}
        onClick={handlePageClick}
        disabled={currentPage === pageNum}>
        {pageNum}
      </button>
    ),
  );

  return (
    <>
      <div className={gridClass}>
        {currentItems.map((item, index) => (
          <div
            key={index}
            className="group mx-auto w-full overflow-hidden rounded-xl bg-white shadow duration-500 ease-in-out hover:shadow-xl dark:bg-slate-900 dark:shadow-gray-700 dark:hover:shadow-xl dark:hover:shadow-gray-700 lg:max-w-2xl">
            <div className="md:flex">
              <div className="relative md:shrink-0">
                <img
                  className="h-full w-full object-cover md:w-48"
                  src={item.image}
                  alt=""
                />
                <div className="absolute end-4 top-4">
                  <Link
                    to="#"
                    className="btn btn-icon rounded-full bg-white text-slate-100 shadow hover:text-red-600 focus:text-red-600 dark:bg-slate-900 dark:text-slate-700 dark:shadow-gray-700 dark:hover:text-red-600 dark:focus:text-red-600">
                    <i className="mdi mdi-heart mdi-18px"></i>
                  </Link>
                </div>
              </div>
              <div className="p-6">
                <div className="pb-6 md:pb-4">
                  <Link
                    to={`/property-detail/${item.id}`}
                    className="text-lg font-medium duration-500 ease-in-out hover:text-blue-600">
                    10765 Hillshire Ave, Baton Rouge, LA 70810, USA
                  </Link>
                </div>

                <ul className="flex list-none items-center border-y border-slate-100 py-6 dark:border-gray-800 md:py-4">
                  <li className="me-4 flex items-center">
                    <i className="uil uil-compress-arrows me-2 text-2xl text-blue-600"></i>
                    <span>{item.square}sqf</span>
                  </li>

                  <li className="me-4 flex items-center">
                    <i className="uil uil-bed-double me-2 text-2xl text-blue-600"></i>
                    <span>{item.beds} Beds</span>
                  </li>

                  <li className="flex items-center">
                    <i className="uil uil-bath me-2 text-2xl text-blue-600"></i>
                    <span>{item.baths} Baths</span>
                  </li>
                </ul>

                <ul className="flex list-none items-center justify-between pt-6 md:pt-4">
                  <li>
                    <span className="text-slate-400">Price</span>
                    <p className="text-lg font-medium">${item.price}</p>
                  </li>

                  <li>
                    <span className="text-slate-400">Rating</span>
                    <ul className="list-none text-lg font-medium text-amber-400">
                      <li className="ms-1 inline">
                        <i className="mdi mdi-star"></i>
                      </li>
                      <li className="ms-1 inline">
                        <i className="mdi mdi-star"></i>
                      </li>
                      <li className="ms-1 inline">
                        <i className="mdi mdi-star"></i>
                      </li>
                      <li className="ms-1 inline">
                        <i className="mdi mdi-star"></i>
                      </li>
                      <li className="ms-1 inline">
                        <i className="mdi mdi-star"></i>
                      </li>
                      <li className="ms-1 inline text-black dark:text-white">
                        {item.rating}(30)
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-8 grid grid-cols-1 md:grid-cols-12">
        <div className="text-center md:col-span-12">
          <nav>
            <ul className="inline-flex items-center -space-x-px">
              <li>
                <Link
                  to="#"
                  className="mx-1 inline-flex h-10 w-10 items-center justify-center rounded-full bg-white text-slate-400 shadow-sm hover:border-blue-600 hover:bg-blue-600 hover:text-white dark:bg-slate-900 dark:shadow-gray-700 dark:hover:border-blue-600 dark:hover:bg-blue-600">
                  <i className="uil uil-angle-left text-[20px]"></i>
                </Link>
              </li>
              <li>{pageButtons}</li>
              <li>
                <Link
                  to="#"
                  className="mx-1 inline-flex h-10 w-10 items-center justify-center rounded-full bg-white text-slate-400 shadow-sm hover:border-blue-600 hover:bg-blue-600 hover:text-white dark:bg-slate-900 dark:shadow-gray-700 dark:hover:border-blue-600 dark:hover:bg-blue-600">
                  <i className="uil uil-angle-right text-[20px]"></i>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}
