import React from 'react';
import { Link } from 'react-router-dom';

import properties from './data';

export default function Property() {
  return (
    <>
      <div className="container mt-16 lg:mt-24">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3 className="mb-4 text-2xl font-semibold leading-normal md:text-3xl md:leading-normal">
            Featured Properties
          </h3>

          <p className="mx-auto max-w-xl text-slate-400">
            "Himalayaz LLC: Your Partner in Innovative AI and Software
            Solutions."
          </p>
        </div>

        <div className="mt-8 grid grid-cols-1 gap-[30px] md:grid-cols-2 lg:grid-cols-3">
          {properties.map((item, index) => (
            <div
              className="group overflow-hidden rounded-xl bg-white shadow duration-500 ease-in-out hover:shadow-xl dark:bg-slate-900 dark:shadow-gray-700 dark:hover:shadow-xl dark:hover:shadow-gray-700"
              key={index}>
              <div className="relative">
                <img src={item.image} alt="" />

                <div className="absolute end-4 top-4">
                  <Link
                    to="#"
                    className="btn btn-icon rounded-full bg-white text-slate-100 shadow hover:text-red-600 focus:text-red-600 dark:bg-slate-900 dark:text-slate-700 dark:shadow-gray-700 dark:hover:text-red-600 dark:focus:text-red-600">
                    <i className="mdi mdi-heart mdi-18px"></i>
                  </Link>
                </div>
              </div>

              <div className="p-6">
                <div className="pb-6">
                  <Link
                    to={`/property-detail/${item.id}`}
                    className="text-lg font-medium duration-500 ease-in-out hover:text-blue-600">
                    {item.name}
                  </Link>
                </div>

                <ul className="flex list-none items-center border-y border-slate-100 py-6 dark:border-gray-800">
                  <li className="me-4 flex items-center">
                    <i className="uil uil-compress-arrows me-2 text-2xl text-blue-600"></i>
                    <span>{item.square}sqf</span>
                  </li>

                  <li className="me-4 flex items-center">
                    <i className="uil uil-bed-double me-2 text-2xl text-blue-600"></i>
                    <span>{item.beds} Beds</span>
                  </li>

                  <li className="flex items-center">
                    <i className="uil uil-bath me-2 text-2xl text-blue-600"></i>
                    <span>{item.baths} Baths</span>
                  </li>
                </ul>

                <ul className="flex list-none items-center justify-between pt-6">
                  <li>
                    <span className="text-slate-400">Price</span>
                    <p className="text-lg font-medium">${item.price}</p>
                  </li>

                  <li>
                    <span className="text-slate-400">Rating</span>
                    <ul className="list-none text-lg font-medium text-amber-400">
                      <li className="ms-1 inline">
                        <i className="mdi mdi-star"></i>
                      </li>
                      <li className="ms-1 inline">
                        <i className="mdi mdi-star"></i>
                      </li>
                      <li className="ms-1 inline">
                        <i className="mdi mdi-star"></i>
                      </li>
                      <li className="ms-1 inline">
                        <i className="mdi mdi-star"></i>
                      </li>
                      <li className="ms-1 inline">
                        <i className="mdi mdi-star"></i>
                      </li>
                      <li className="ms-1 inline text-black dark:text-white">
                        {item.rating}(30)
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
