import React, { useState } from 'react';
import { Link as Link2 } from 'react-router-dom';
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import Switcher from '../component/Switcher';
import BackgroudImage from '../assets/images/himalayaz-small.jpg';

import { User, Mail, MessageCircle } from 'react-feather';
import axios from 'axios';

import Poperty1Image from '../assets/images/content_base.jpg';

export default function BlogDetail2() {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [comments, setComments] = useState('');

  const submitFormHandler = async () => {
    const data = {
      name,
      email,
      comments,
    };
    try {
      axios
        .post(
          'https://himalayazform-default-rtdb.firebaseio.com/blogComments.json',
          data,
          {
            headers: { 'Content-Type': 'application/json' },
          },
        )
        .then(function (response) {
          setComments('');
          setName('');
          setEmail('');
          console.log('Form data sent successfully.', response);
        })
        .catch(function (error) {
          setComments('');
          setName('');
          setEmail('');
          console.log(error);
        });
      console.log('Form data sent successfully.');
    } catch (error) {
      console.error('Error sending form data:', error);
    }
  };
  return (
    <>
      <Navbar navClass="navbar-white" />

      <section
        style={{ backgroundImage: `url(${BackgroudImage})` }}
        className="relative table w-full  bg-cover bg-center bg-no-repeat py-32 lg:py-36">
        <div className="absolute inset-0 bg-black opacity-80"></div>
        <div className="container">
          <div className="mt-10 grid grid-cols-1 text-center">
            <h3 className="mb-3 text-2xl font-medium leading-snug tracking-wide text-white md:text-3xl md:leading-snug">
              The Future of the Content-Based Economy
            </h3>
          </div>
        </div>
      </section>
      <div className="relative">
        <div className="shape z-1 overflow-hidden text-white dark:text-slate-900">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"></path>
          </svg>
        </div>
      </div>

      <section className="relative py-16 md:py-24">
        <div className="container">
          <div className="grid grid-cols-1 gap-[30px] md:grid-cols-1 lg:grid-cols-12">
            <div className="order-2 md:order-1 lg:col-span-12">
              <div className="relative overflow-hidden rounded-xl shadow dark:shadow-gray-800">
                <img src={Poperty1Image} alt="" />

                <div className="p-6">
                  <p className="text-slate-400">
                    In the digital age, content is king. From social media
                    influencers to businesses and solopreneurs, creating
                    valuable content is key to success in today's world. But
                    with so much content being produced every day, it can be
                    difficult for content creators to stand out from the crowd
                    and monetize their efforts. This is where technology comes
                    in, and at Himalayaz LLC, we specialize in helping content
                    creators unlock value and achieve their business goals.
                    Here's a look at the future of the content-based economy and
                    how we can help you succeed.
                  </p>
                  <p className="mt-3 rounded-ee-xl rounded-ss-xl border-x-4 border-blue-600 p-3 italic text-slate-400">
                    "According to a report by Digital Marketing Institute,
                    content marketing is set to become a $300 billion industry
                    by 2023. This highlights the growing importance of content
                    in the digital economy and the huge potential for content
                    creators to monetize their efforts. However, with so much
                    content being produced every day, it's important to stand
                    out from the crowd and create content that resonates with
                    your audience."
                  </p>
                  <p className="mt-3 text-slate-400">
                    The future of the content-based economy looks bright, and
                    technology will play a key role in helping content creators
                    unlock value from their efforts. At Himalayaz LLC, we
                    specialize in using cutting-edge tools and generative AI to
                    help content creators achieve their business goals. Whether
                    you're an influencer, business, or solopreneur, we can help
                    you succeed in the digital economy. Contact us today to
                    learn more.
                  </p>
                </div>
              </div>

              <div className="mt-8 rounded-md p-6 shadow dark:shadow-gray-800">
                <h5 className="text-lg font-semibold">Leave A Comment:</h5>

                {/* <form className="mt-8"> */}
                <div className="mt-8 grid lg:grid-cols-12 lg:gap-6">
                  <div className="mb-5 lg:col-span-6">
                    <div className="text-left">
                      <label htmlFor="name" className="font-semibold">
                        Your Name:
                      </label>
                      <div className="form-icon relative mt-2">
                        <User className="absolute start-4 top-3 h-4 w-4" />
                        <input
                          name="name"
                          id="name"
                          type="text"
                          value={name}
                          onChange={e => {
                            setName(e.target.value);
                          }}
                          className="form-input ps-11"
                          placeholder="Name :"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mb-5 lg:col-span-6">
                    <div className="text-left">
                      <label htmlFor="email" className="font-semibold">
                        Your Email:
                      </label>
                      <div className="form-icon relative mt-2">
                        <Mail className="absolute start-4 top-3 h-4 w-4" />
                        <input
                          name="email"
                          id="email"
                          type="email"
                          value={email}
                          onChange={e => {
                            setEmail(e.target.value);
                          }}
                          className="form-input ps-11"
                          placeholder="Email :"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1">
                  <div className="mb-5">
                    <div className="text-left">
                      <label htmlFor="comments" className="font-semibold">
                        Your Comment:
                      </label>
                      <div className="form-icon relative mt-2">
                        <MessageCircle className="absolute start-4 top-3 h-4 w-4" />
                        <textarea
                          name="comments"
                          id="comments"
                          value={comments}
                          onChange={e => {
                            setComments(e.target.value);
                          }}
                          className="form-input h-28 ps-11"
                          placeholder="Message :"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  id="submit"
                  name="send"
                  onClick={submitFormHandler}
                  className="btn w-full rounded-md border-blue-600 bg-blue-600 text-white hover:border-blue-700 hover:bg-blue-700">
                  Send Message
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Switcher />
    </>
  );
}
