import React, { useState } from 'react';
import { Link as Link2 } from 'react-router-dom';
import ModalVideo from 'react-modal-video';

import Image from '../assets/images/about.jpg';
import '../../../../node_modules/react-modal-video/scss/modal-video.scss';

export default function About() {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <div className="container mt-16 lg:mt-24">
        <div className="grid grid-cols-1 items-center gap-[30px] text-center md:grid-cols-12">
          {/* <div className="md:col-span-5">
            <div className="relative">
              <img src={Image} className="rounded-xl shadow-md" alt="" />
              <div className="absolute bottom-2/4 end-0 start-0 translate-y-2/4 text-center">
                <Link2
                  to="#"
                  onClick={() => setOpen(true)}
                  data-type="youtube"
                  data-id="yba7hPeTSjk"
                  className="lightbox dark:shadow-gyay-700 inline-flex h-20 w-20 items-center justify-center rounded-full bg-white text-blue-600 shadow-md dark:bg-slate-900">
                  <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                </Link2>
              </div>
            </div>
          </div> */}

          <div className="md:col-span-12">
            <div className="lg:ms-4 ">
              <h4 className="mb-6 w-full text-2xl font-semibold leading-normal md:text-3xl lg:leading-normal">
                Mission
              </h4>
              <p className="w-full text-slate-400">
                Himalayaz LLC is dedicated to driving business success and
                sustainability through innovative software solutions
              </p>

              {/* <div className="mt-4">
                <Link2
                  to="#"
                  className="btn mt-3 rounded-md bg-blue-600 text-white hover:bg-blue-700">
                  Learn More{' '}
                </Link2>
              </div> */}

              <h4 className="mb-6 mt-8 w-full text-2xl font-semibold leading-normal md:text-3xl lg:leading-normal">
                About Us:
              </h4>
              <p className="w-full text-slate-400">
                At Himalayaz LLC, our mission is to enable organizations to
                achieve their business goals while reducing environmental
                impact. We believe well-designed technology solutions create
                value for companies and communities alike.
              </p>

              <p className="w-full text-slate-400">
                Our approach is rooted in developing intelligent software that
                drives efficiency, optimizes operations, and helps organizations
                thrive sustainably. We aim to craft solutions that reduce waste,
                conserve resources, and pave the way for mindful growth.
              </p>

              <p className="w-full text-slate-400">
                Our commitment to sustainability informs all aspects of our
                business. From our own sustainable operations to our
                partnerships with eco-conscious vendors, we hold ourselves to
                high standards of environmental stewardship.
              </p>

              <p className="w-full text-slate-400">
                Our team shares a passion for using technology as a force for
                good in the world. We thrive on collaborating with
                forward-thinking clients to create solutions that merge business
                success with social responsibility.
              </p>

              <p className="w-full text-slate-400">
                At Himalayaz, we recognize the immense power technology holds to
                shape the future. We wield that power purposefully by
                engineering intelligent software that enables smarter, more
                sustainable business practices. Join us in driving your
                organization's success while benefiting the planet.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ModalVideo
        isOpen={isOpen}
        channel="youtube"
        videoId="yba7hPeTSjk"
        onClose={() => setOpen(false)}
      />
    </>
  );
}
