import React, { useState } from 'react';
import { Link as Link2 } from 'react-router-dom';
import Select from 'react-select';

import Navbar from '../component/Navbar';
import BackgroundImage from '../assets/images/himalayaz-1.jpg';
import Footer from '../component/Footer';
import Switcher from '../component/Switcher';
import Blog from '../component/Blog';

const Houses = [
  { value: 'AF', label: 'Apartment' },
  { value: 'AZ', label: ' Offices' },
  { value: 'BS', label: 'Townhome' },
];
const minPrice = [
  { value: '1', label: '500' },
  { value: '2', label: '1000' },
  { value: '3', label: '2000' },
  { value: '4', label: '3000' },
  { value: '5', label: '4000' },
  { value: '5', label: '5000' },
  { value: '5', label: '6000' },
];
const maxPrice = [
  { value: '1', label: '500' },
  { value: '2', label: '1000' },
  { value: '3', label: '2000' },
  { value: '4', label: '3000' },
  { value: '5', label: '4000' },
  { value: '5', label: '5000' },
  { value: '5', label: '6000' },
];

export default function Index() {
  const [activeTabIndex, setactiveTabIndex] = useState(0);

  const handleTabClick = tabIndex => {
    setactiveTabIndex(tabIndex);
  };

  return (
    <>
      <Navbar />
      {/* Hero Start  */}
      <section className="relative mt-20">
        <div className="container-fluid mx-2 md:mx-4">
          <div
            style={{ backgroundImage: `url(${BackgroundImage})` }}
            className="relative table w-full overflow-hidden rounded-2xl
            bg-[url('../../assets/images/himalayaz-1.jpg')] bg-cover bg-center bg-no-repeat 
            pb-52 pt-40 shadow-md"
            id="home">
            <div className="absolute inset-0 bg-black/60"></div>

            <div className="container">
              <div className="grid grid-cols-1">
                <div className="text-center ltr:md:text-left rtl:md:text-right">
                  <h1 className="mb-6 text-4xl font-bold leading-normal text-white lg:text-5xl lg:leading-normal">
                    Intelligent software for smarter business. <br /> Our{' '}
                    <span className="text-blue-600">
                      Mission, Vision, and Philosophy{' '}
                    </span>
                  </h1>
                  <p className="max-w-xl text-xl text-white/70">
                    are centered around the idea that technology can help us
                    create a more sustainable future. We are committed to doing
                    our part to make this vision a reality, and we invite others
                    to join us in this mission.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Hero End */}

      <section className="relative pb-16 md:pb-24">
        <div className="container">
          <div className="grid grid-cols-1 justify-center">
            {/* <div className="relative -mt-32">
              <div className="grid grid-cols-1">
                <ul
                  className="inline-block w-full flex-wrap justify-center rounded-t-xl border-b bg-white p-4 text-center dark:border-gray-800 dark:bg-slate-900 sm:w-fit"
                  id="myTab"
                  data-tabs-toggle="#StarterContent"
                  role="tablist">
                  <li role="presentation" className="inline-block">
                    <button
                      onClick={() => handleTabClick(0)}
                      className={`w-full rounded-md px-6 py-2 text-base font-medium transition-all duration-500 ease-in-out ${activeTabIndex === 0
                        ? 'bg-blue-600 text-white'
                        : 'hover:text-blue-600'
                        }`}
                      id="buy-home-tab"
                      data-tabs-target="#buy-home"
                      type="button"
                      role="tab"
                      aria-controls="buy-home"
                      aria-selected="true">
                      Buy
                    </button>
                  </li>
                  <li role="presentation" className="inline-block">
                    <button
                      onClick={() => handleTabClick(1)}
                      className={`w-full rounded-md px-6 py-2 text-base font-medium transition-all duration-500 ease-in-out ${activeTabIndex === 1
                        ? 'bg-blue-600 text-white'
                        : 'hover:text-blue-600'
                        }`}
                      id="sell-home-tab"
                      data-tabs-target="#sell-home"
                      type="button"
                      role="tab"
                      aria-controls="sell-home"
                      aria-selected="false">
                      Sell
                    </button>
                  </li>
                  <li role="presentation" className="inline-block">
                    <button
                      onClick={() => handleTabClick(2)}
                      className={`w-full rounded-md px-6 py-2 text-base font-medium transition-all duration-500 ease-in-out ${activeTabIndex === 2
                        ? 'bg-blue-600 text-white'
                        : 'hover:text-blue-600'
                        }`}
                      id="rent-home-tab"
                      data-tabs-target="#rent-home"
                      type="button"
                      role="tab"
                      aria-controls="rent-home"
                      aria-selected="false">
                      Rent
                    </button>
                  </li>
                </ul>

                <div
                  id="StarterContent"
                  className="rounded-xl rounded-se-none rounded-ss-none bg-white p-6 shadow-md dark:bg-slate-900 dark:shadow-gray-700 md:rounded-se-xl">
                  {activeTabIndex === 0 && (
                    <div
                      id="buy-home"
                      role="tabpanel"
                      aria-labelledby="buy-home-tab">
                      <form action="#">
                        <div className="registration-form text-dark text-start">
                          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 lg:gap-0">
                            <div>
                              <label className="form-label font-medium text-slate-900 dark:text-white">
                                Search : <span className="text-red-600">*</span>
                              </label>
                              <div className="filter-search-form filter-border relative mt-2">
                                <i className="uil uil-search icons"></i>
                                <input
                                  name="name"
                                  type="text"
                                  id="job-keyword"
                                  className="form-input filter-input-box border-0 bg-gray-50 dark:bg-slate-800"
                                  placeholder="Search your Keywords"
                                />
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="buy-properties"
                                className="form-label font-medium text-slate-900 dark:text-white">
                                Select Categories:
                              </label>
                              <div className="filter-search-form filter-border relative mt-2">
                                <i className="uil uil-estate icons"></i>
                                <Select
                                  className="form-input filter-input-box border-0 bg-gray-50 dark:bg-slate-800"
                                  options={Houses}
                                />
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="buy-min-price"
                                className="form-label font-medium text-slate-900 dark:text-white">
                                Min Price :
                              </label>
                              <div className="filter-search-form filter-border relative mt-2">
                                <i className="uil uil-usd-circle icons"></i>
                                <Select
                                  className="form-input filter-input-box border-0 bg-gray-50 dark:bg-slate-800"
                                  options={minPrice}
                                />
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="buy-max-price"
                                className="form-label font-medium text-slate-900 dark:text-white">
                                Max Price :
                              </label>
                              <div className="filter-search-form relative mt-2">
                                <i className="uil uil-usd-circle icons"></i>
                                <Select
                                  className="form-input filter-input-box border-0 bg-gray-50 dark:bg-slate-800"
                                  options={maxPrice}
                                />
                              </div>
                            </div>

                            <div className="lg:mt-6">
                              <input
                                type="submit"
                                id="search-buy"
                                name="search"
                                className="btn searchbtn submit-btn !h-12 w-full rounded border-blue-600 bg-blue-600 text-white hover:border-blue-700 hover:bg-blue-700"
                                value="Search"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                  {activeTabIndex === 1 && (
                    <div
                      id="sell-home"
                      role="tabpanel"
                      aria-labelledby="sell-home-tab">
                      <form action="#">
                        <div className="registration-form text-dark text-start">
                          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 lg:gap-0">
                            <div>
                              <label className="form-label font-medium text-slate-900 dark:text-white">
                                Search : <span className="text-red-600">*</span>
                              </label>
                              <div className="filter-search-form filter-border relative mt-2">
                                <i className="uil uil-search icons"></i>
                                <input
                                  name="name"
                                  type="text"
                                  id="job-keyword"
                                  className="form-input filter-input-box border-0 bg-gray-50 dark:bg-slate-800"
                                  placeholder="Search your Keywords"
                                />
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="buy-properties"
                                className="form-label font-medium text-slate-900 dark:text-white">
                                Select Categories:
                              </label>
                              <div className="filter-search-form filter-border relative mt-2">
                                <i className="uil uil-estate icons"></i>
                                <Select
                                  className="form-input filter-input-box border-0 bg-gray-50 dark:bg-slate-800"
                                  options={Houses}
                                />
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="buy-min-price"
                                className="form-label font-medium text-slate-900 dark:text-white">
                                Min Price :
                              </label>
                              <div className="filter-search-form filter-border relative mt-2">
                                <i className="uil uil-usd-circle icons"></i>
                                <Select
                                  className="form-input filter-input-box border-0 bg-gray-50 dark:bg-slate-800"
                                  options={minPrice}
                                />
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="buy-max-price"
                                className="form-label font-medium text-slate-900 dark:text-white">
                                Max Price :
                              </label>
                              <div className="filter-search-form relative mt-2">
                                <i className="uil uil-usd-circle icons"></i>
                                <Select
                                  className="form-input filter-input-box border-0 bg-gray-50 dark:bg-slate-800"
                                  options={maxPrice}
                                />
                              </div>
                            </div>

                            <div className="lg:mt-6">
                              <input
                                type="submit"
                                id="search-buy"
                                name="search"
                                className="btn searchbtn submit-btn !h-12 w-full rounded border-blue-600 bg-blue-600 text-white hover:border-blue-700 hover:bg-blue-700"
                                value="Search"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                  {activeTabIndex === 2 && (
                    <div
                      id="rent-home"
                      role="tabpanel"
                      aria-labelledby="rent-home-tab">
                      <form action="#">
                        <div className="registration-form text-dark text-start">
                          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 lg:gap-0">
                            <div>
                              <label className="form-label font-medium text-slate-900 dark:text-white">
                                Search : <span className="text-red-600">*</span>
                              </label>
                              <div className="filter-search-form filter-border relative mt-2">
                                <i className="uil uil-search icons"></i>
                                <input
                                  name="name"
                                  type="text"
                                  id="job-keyword"
                                  className="form-input filter-input-box border-0 bg-gray-50 dark:bg-slate-800"
                                  placeholder="Search your Keywords"
                                />
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="buy-properties"
                                className="form-label font-medium text-slate-900 dark:text-white">
                                Select Categories:
                              </label>
                              <div className="filter-search-form filter-border relative mt-2">
                                <i className="uil uil-estate icons"></i>
                                <Select
                                  className="form-input filter-input-box border-0 bg-gray-50 dark:bg-slate-800"
                                  options={Houses}
                                />
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="buy-min-price"
                                className="form-label font-medium text-slate-900 dark:text-white">
                                Min Price :
                              </label>
                              <div className="filter-search-form filter-border relative mt-2">
                                <i className="uil uil-usd-circle icons"></i>
                                <Select
                                  className="form-input filter-input-box border-0 bg-gray-50 dark:bg-slate-800"
                                  options={minPrice}
                                />
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="buy-max-price"
                                className="form-label font-medium text-slate-900 dark:text-white">
                                Max Price :
                              </label>
                              <div className="filter-search-form relative mt-2">
                                <i className="uil uil-usd-circle icons"></i>
                                <Select
                                  className="form-input filter-input-box border-0 bg-gray-50 dark:bg-slate-800"
                                  options={maxPrice}
                                />
                              </div>
                            </div>

                            <div className="lg:mt-6">
                              <input
                                type="submit"
                                id="search-buy"
                                name="search"
                                className="btn searchbtn submit-btn !h-12 w-full rounded border-blue-600 bg-blue-600 text-white hover:border-blue-700 hover:bg-blue-700"
                                value="Search"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <Blog />
        {/* <About /> */}
        {/* <Feature /> */}
        {/* <Property /> */}
        {/* <Client /> */}
        <div className="container mt-16 lg:mt-24">
          <div className="grid grid-cols-1 text-center">
            <h3 className="mb-6 text-2xl font-medium leading-normal text-black dark:text-white md:text-3xl md:leading-normal">
              Have Question ? Get in touch!
            </h3>

            <p className="mx-auto max-w-xl text-slate-400">
              Let's build something amazing together. Reach out now to partner
              with our talented team of technology professionals. We can't wait
              to learn about your business and goal.
            </p>

            <div className="mt-6">
              <Link2
                to="/contact"
                className="btn rounded-md bg-blue-600 text-white hover:bg-blue-700">
                <i className="uil uil-phone me-2 align-middle"></i> Contact us
              </Link2>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Switcher />
      {/* <!-- End --> */}
    </>
  );
}
