import React, { useState } from 'react';
import { Link as Link2 } from 'react-router-dom';
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import Switcher from '../component/Switcher';
import { Link } from 'react-scroll';
import BackgroudImage from '../assets/images/himalayaz-small.jpg';

export default function Faq() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeGeneral, setGeneralIndex] = useState(0);
  const [activePayment, setPaymentIndex] = useState(0);
  const [activeSupport, setSupportIndex] = useState(0);

  const toggleAccordion = index => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const toggleGeneral = index => {
    if (activeGeneral === index) {
      setGeneralIndex(null);
    } else {
      setGeneralIndex(index);
    }
  };

  const togglePayment = index => {
    if (activePayment === index) {
      setPaymentIndex(null);
    } else {
      setPaymentIndex(index);
    }
  };

  const toggleSupport = index => {
    if (activeSupport === index) {
      setSupportIndex(null);
    } else {
      setSupportIndex(index);
    }
  };
  const sections = [
    {
      title: 'How do I get in touch with Himalayaz ?',
      content:
        'You can contact us through the email and phone number listed on our website. One of our team members will get back to you within 1 business day to discuss your needs.',
    },
    {
      title: 'What is the process after I reach out ?',
      content: `After reaching out, we'll schedule a discovery call to understand your business goals, technical needs, and timeline. We'll provide our recommendations and a proposal outlining the scope of work, timeline, and budget.`,
    },
    {
      title: 'How do you charge for projects ?',
      content: `We offer a range of technical services including software development, infrastructure, artificial intelligence, quality assurance, project management, and technical support. See our website for details on our areas of expertise.`,
    },
    {
      title: 'What happens when I receive an order ?',
      content: `We customize each proposal based on your business needs. Most projects are billed on a fixed price basis based on milestones or deliverables. For longer term engagements, we may charge an hourly rate or monthly retainer.`,
    },
    {
      title: 'Do you sign NDAs ?',
      content: `Yes, we are accustomed to signing mutual NDAs with our clients to protect confidentiality before business discussions.`,
    },
    {
      title: 'Do you work with startups ?',
      content: `Absolutely. Many of our clients are early stage startups working to build and launch their MVPs. We scale our approach based on your stage and needs.`,
    },
    {
      title: 'When do you require a contract ?',
      content: `After agreeing on a proposal for services, we'll execute a services agreement outlining the scope, timeline, budget, and terms & conditions. This protects both parties.`,
    },
    {
      title: 'What contract terms do you require ?',
      content: `Our standard contract includes mutual commitments, timeline, budget, invoice schedule, confidentiality, ownership of work product, liability, and other legal protections common in the industry. We aim for reasonable, balanced terms.`,
    },
    {
      title: 'What are your payment terms ?',
      content: `Our standard terms are a deposit upfront, with progress payments made at milestone completion. For ongoing retainer services, monthly payment is required.`,
    },
  ];

  return (
    <>
      <Navbar navClass="navbar-white" />
      {/* <!-- Start Hero --> */}
      <section
        style={{ backgroundImage: `url(${BackgroudImage})` }}
        className="relative table w-full  bg-cover bg-center bg-no-repeat py-32 lg:py-36">
        <div className="absolute inset-0 bg-black opacity-80"></div>
        <div className="container">
          <div className="mt-10 grid grid-cols-1 text-center">
            <h3 className="text-3xl font-medium leading-normal text-white md:text-4xl md:leading-normal">
              Frequently Asked Questions
            </h3>
          </div>
        </div>
      </section>
      <div className="relative">
        <div className="shape z-1 overflow-hidden text-white dark:text-slate-900">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"></path>
          </svg>
        </div>
      </div>
      {/* <!-- End Hero --> */}
      {/* <!-- Start Section--> */}
      <section className="relative py-16 lg:py-24">
        <div className="container">
          <div className="grid grid-cols-1 gap-[30px] md:grid-cols-12">
            <div className="md:col-span-8 lg:col-span-12">
              <div id="tech">
                {/* <h5 className="text-2xl font-semibold"></h5> */}

                <div
                  id="accordion-collapse"
                  data-accordion="collapse"
                  className="mt-6">
                  {sections.map((section, index) => (
                    <div
                      key={index}
                      className="relative mt-4 overflow-hidden rounded-md shadow dark:shadow-gray-700">
                      <h2
                        className="text-base font-medium"
                        id="accordion-collapse-heading-1">
                        <button
                          type="button"
                          onClick={() => toggleAccordion(index)}
                          className={`flex w-full items-center justify-between p-5 text-left font-medium ${
                            activeIndex === index
                              ? 'bg-gray-50 text-blue-600 dark:bg-slate-800'
                              : ''
                          }`}>
                          <span>{section.title}</span>
                          <svg
                            className={`h-4 w-4 shrink-0 ${activeIndex === index ?"rotate-0":"rotate-180" }`}
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              fill-rule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clip-rule="evenodd"></path>
                          </svg>
                        </button>
                      </h2>
                      {activeIndex === index && (
                        <div
                          id="accordion-collapse-body-1"
                          aria-labelledby="accordion-collapse-heading-1">
                          <div className="p-5">
                            <p className="text-slate-400 dark:text-gray-400">
                              {' '}
                              {section.content}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-16 lg:mt-24">
          <div className="grid grid-cols-1 text-center">
            <h3 className="mb-6 text-2xl font-medium leading-normal text-black dark:text-white md:text-3xl md:leading-normal">
              Have Question ? Get in touch!
            </h3>

            <p className="mx-auto max-w-xl text-slate-400">
              Let's build something amazing together. Reach out now to partner
              with our talented team of technology professionals. We can't wait
              to learn about your business and goals.
            </p>

            <div className="mt-6">
              <Link2
                to="/contact"
                className="btn rounded-md bg-blue-600 text-white hover:bg-blue-700">
                <i className="uil uil-phone me-2 align-middle"></i> Contact us
              </Link2>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Switcher />
    </>
  );
}
