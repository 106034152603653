import React, { useEffect } from 'react';
import { Link as Link2 } from 'react-router-dom';
import Navbar from '../component/Navbar';

import BackgroundImage from '../assets/images/bg/01.jpg';
import Feature from '../component/Feature';
import Footer from '../component/Footer';
import Switcher from '../component/Switcher';

export default function Sell() {
  useEffect(() => {
    const rangeSlider = document.getElementById('slider');
    const value = rangeSlider.value;
    document.getElementById('amount-label').innerHTML = value;
    document.getElementById('saving-label').innerHTML = parseFloat(
      value * 0.01,
    ).toFixed(2);
    window.scrollTo(0, 0);
  });

  const handleChange = e => {
    console.log(e.target.value, '32');
    const value = e.target.value;
    document.getElementById('amount-label').innerHTML = value;
    document.getElementById('saving-label').innerHTML = parseFloat(
      value * 0.01,
    ).toFixed(2);
  };

  return (
    <>
      <Navbar navClass="navbar-white" />
      {/* <!-- Start Hero --> */}

      <section
        style={{ backgroundImage: `url(${BackgroundImage})` }}
        className="relative table w-full bg-[url('../../assets/images/bg/01.jpg')] bg-cover bg-center bg-no-repeat py-32 lg:py-36">
        <div className="absolute inset-0 bg-black opacity-80"></div>
        <div className="container">
          <div className="mt-10 grid grid-cols-1 text-center">
            <h3 className="text-3xl font-medium leading-normal text-white md:text-4xl md:leading-normal">
              Sell Faster. Save Thousands.
            </h3>
          </div>
        </div>
      </section>
      <div className="relative">
        <div className="shape z-1 overflow-hidden text-white dark:text-slate-900">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"></path>
          </svg>
        </div>
      </div>

      {/* End Hero  */}
      <section className="relative pb-16 md:pb-24">
        <Feature />
        <div className="container mt-16 lg:mt-24">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 text-2xl font-semibold leading-normal md:text-3xl md:leading-normal">
              Brokerage Calculator
            </h3>

            <p className="mx-auto max-w-xl text-slate-400">
              Himalayaz LLC: Your Partner in Innovative AI and Software
              Solutions.
            </p>
          </div>

          <div className="mt-8 justify-center md:flex">
            <div className="md:w-4/5 lg:w-3/5">
              <div
                className="rounded-md p-6 shadow dark:shadow-gray-700"
                role="form">
                <ul className="flex list-none justify-between">
                  <li className="h6">Min $ 10000</li>
                  <li className="h6">Max $ 200000</li>
                </ul>

                <div className="row">
                  <div className="col-sm-12 mb-4">
                    <label htmlFor="slider" className="form-label"></label>
                    <input
                      id="slider"
                      onInput={handleChange}
                      type="range"
                      defaultValue="10000"
                      min="10000"
                      max="200000"
                      className="h-2 w-full cursor-pointer appearance-none rounded-lg bg-gray-100 dark:bg-gray-700"
                    />
                  </div>
                </div>

                <ul className="list-none justify-between text-center md:flex">
                  <li>
                    <ul className="text-md-start brokerage-form list-none">
                      <li className="font-medium">
                        <label className="control-label">Total Value ($)</label>
                      </li>
                      <li>
                        <input
                          type="hidden"
                          id="amount"
                          className="form-control"
                        />
                        <span className="text-blue-600">$</span>{' '}
                        <p
                          className="h5 inline-block text-blue-600"
                          id="amount-label"></p>
                      </li>
                    </ul>
                  </li>

                  <li className="mt-sm-0 mt-2">
                    <ul className="text-md-end brokerage-form list-none">
                      <li className="font-medium">
                        <label className="control-label">
                          Brokerage Fee ($)
                        </label>
                      </li>
                      <li>
                        <input
                          type="hidden"
                          id="saving"
                          className="form-control"
                        />
                        <span className="text-blue-600">$</span>{' '}
                        <p
                          className="h5 inline-block text-blue-600"
                          id="saving-label"></p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-16 lg:mt-24">
          <div className="grid grid-cols-1 text-center">
            <h3 className="mb-6 text-2xl font-medium leading-normal text-black dark:text-white md:text-3xl md:leading-normal">
              Have Question ? Get in touch!
            </h3>

            <p className="mx-auto max-w-xl text-slate-400">
              Himalayaz LLC: Your Partner in Innovative AI and Software
              Solutions.
            </p>

            <div className="mt-6">
              <Link2
                to="/contact"
                className="btn rounded-md bg-blue-600 text-white hover:bg-blue-700">
                <i className="uil uil-phone me-2 align-middle"></i> Contact us
              </Link2>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Switcher />
    </>
  );
}
