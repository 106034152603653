import React, { useState, useEffect } from 'react';
import LogoLight from '../assets/images/logo-icon-64.png';
import BackgroundImage from '../assets/images/bg/01.jpg';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import Switcher from '../component/Switcher';

export default function Maintenance() {
  const [minutes, setMinutes] = useState(0);
  const [remainingSeconds, setRemainingSeconds] = useState(0);
  const particlesInit = async main => {
    console.log(main);
    // initialize the tsParticles instance
    await loadFull(main);
  };
  const particlesLoaded = container => {
    console.log(container);
  };
  useEffect(() => {
    let intervalId = setInterval(() => {
      calculateTimeRemaining();
    }, 1000);

    var seconds = 3599;
    function calculateTimeRemaining() {
      const minutes = Math.round((seconds - 30) / 60);
      const remainingSeconds = seconds % 60;

      setMinutes(minutes);
      setRemainingSeconds(remainingSeconds);

      if (seconds === 0) {
        clearInterval(intervalId);
      } else {
        seconds = seconds - 1;
      }
    }

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <section className="zoom-image relative flex items-center justify-center overflow-hidden py-36 md:h-screen">
        <div
          style={{ backgroundImage: `url(${BackgroundImage})` }}
          className="image-wrap z-1 absolute inset-0 bg-cover bg-center bg-no-repeat"></div>
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          className="z-2 absolute inset-0 bg-gradient-to-b from-transparent to-black"
          options={{
            particles: {
              number: {
                value: 250,
                density: {
                  enable: false,
                  value_area: 800,
                },
              },
              color: {
                value: '#ffffff',
              },
              shape: {
                type: 'circle',
                stroke: {
                  width: 0,
                  color: '#000000',
                },
                polygon: {
                  nb_sides: 36,
                },
                image: {
                  src: '',
                  width: 1000,
                  height: 1000,
                },
              },
              opacity: {
                value: 0.5,
                random: false,
                anim: {
                  enable: false,
                  speed: 0.5,
                  opacity_min: 1,
                  sync: false,
                },
              },
              size: {
                value: 3.2,
                random: true,
                anim: {
                  enable: false,
                  speed: 20,
                  size_min: 0.1,
                  sync: false,
                },
              },
              line_linked: {
                enable: false,
                distance: 100,
                color: '#ffffff',
                opacity: 0.4,
                width: 2,
              },
              move: {
                enable: true,
                speed: 1,
                direction: 'bottom',
                random: false,
                straight: false,
                out_mode: 'out',
                bounce: false,
                attract: {
                  enable: false,
                  rotateX: 800,
                  rotateY: 1200,
                },
              },
            },
            interactivity: {
              detect_on: 'canvas',
              events: {
                onhover: {
                  enable: false,
                  mode: 'repulse',
                },
                onclick: {
                  enable: false,
                  mode: 'push',
                },
                resize: true,
              },
              modes: {
                grab: {
                  distance: 200,
                  line_linked: {
                    opacity: 1,
                  },
                },
                bubble: {
                  distance: 400,
                  size: 40,
                  duration: 2,
                  opacity: 8,
                  speed: 3,
                },
                repulse: {
                  distance: 71,
                  duration: 0.4,
                },
                push: {
                  particles_nb: 4,
                },
                remove: {
                  particles_nb: 2,
                },
              },
            },
            retina_detect: true,
          }}
        />
        <div className="z-3 container relative text-center">
          <div className="grid grid-cols-1">
            <img src={LogoLight} className="mx-auto" alt="" />
            <h1 className="mb-6 mt-8 text-3xl font-bold text-white md:text-5xl">
              We Are Back Soon...
            </h1>
            <p className="mx-auto max-w-xl text-lg text-white/70">
              Himalayaz LLC: Your Partner in Innovative AI and Software
              Solutions.
            </p>
          </div>
          <div className="mt-10 grid grid-cols-1">
            <div className="text-center">
              <span
                id="maintenance"
                className="timer text-[56px] tracking-[1px] text-white">
                {minutes}:{remainingSeconds}
              </span>
              <span className="block text-base font-semibold uppercase text-white">
                Minutes
              </span>
            </div>
          </div>
          <div className="mt-8 grid grid-cols-1">
            <div className="subcribe-form text-center">
              <form className="relative mx-auto max-w-xl">
                <input
                  type="email"
                  id="subemail"
                  name="name"
                  className="h-[50px] w-full rounded-full border bg-white/70 pb-4 pe-40 ps-6 pt-4 text-black outline-none dark:border-gray-700 dark:bg-slate-900/70 dark:text-white"
                  placeholder="Enter your email id.."
                />
                <button
                  type="submit"
                  className="btn absolute end-[3px] top-[2px] h-[46px] rounded-full border-blue-600 bg-blue-600 text-white hover:border-blue-700 hover:bg-blue-700">
                  Subcribe Now
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* <!--end section --> */}
      <Switcher />
    </>
  );
}
