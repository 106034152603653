import Property1 from '../assets/images/property/1.jpg';
import Property2 from '../assets/images/property/2.jpg';
import Property3 from '../assets/images/property/3.jpg';
import Property4 from '../assets/images/property/4.jpg';
import Property5 from '../assets/images/property/5.jpg';
import Property6 from '../assets/images/property/6.jpg';
import Property7 from '../assets/images/property/7.jpg';
import Property8 from '../assets/images/property/8.jpg';
import Property9 from '../assets/images/property/9.jpg';
import Property10 from '../assets/images/property/10.jpg';
import Property11 from '../assets/images/property/11.jpg';
import Property12 from '../assets/images/property/12.jpg';

const properties = [
  {
    id: 1,
    image: Property1,
    name: '10765 Hillshire Ave, Baton Rouge, LA 70810, USA',
    square: 8000,
    beds: 4,
    baths: 4,
    price: 5000,
    rating: 5.0,
  },
  {
    id: 2,
    image: Property2,
    name: '59345 STONEWALL DR, Plaquemine, LA 70764, USA',
    square: 8000,
    beds: 4,
    baths: 4,
    price: 5000,
    rating: 5.0,
  },
  {
    id: 3,
    image: Property3,
    name: '3723 SANDBAR DR, Addis, LA 70710, USA',
    square: 8000,
    beds: 4,
    baths: 4,
    price: 5000,
    rating: 5.0,
  },
  {
    id: 4,
    image: Property4,
    name: 'Lot 21 ROYAL OAK DR, Prairieville, LA 70769, USA',
    square: 8000,
    beds: 4,
    baths: 4,
    price: 5000,
    rating: 5.0,
  },
  {
    id: 5,
    image: Property5,
    name: '710 BOYD DR, Unit #1102, Baton Rouge, LA 70808, USA',
    square: 8000,
    beds: 4,
    baths: 4,
    price: 5000,
    rating: 5.0,
  },
  {
    id: 6,
    image: Property6,
    name: '5133 MCLAIN WAY, Baton Rouge, LA 70809, USA',
    square: 8000,
    beds: 4,
    baths: 4,
    price: 5000,
    rating: 5.0,
  },
  {
    id: 7,
    image: Property7,
    name: '2141 Fiero Street, Baton Rouge, LA 70808',
    square: 8000,
    beds: 4,
    baths: 4,
    price: 5000,
    rating: 5,
  },
  {
    id: 8,
    image: Property8,
    name: '9714 Inniswold Estates Ave, Baton Rouge, LA 70809',
    square: 8000,
    beds: 4,
    baths: 4,
    price: 5000,
    rating: 5,
  },
  {
    id: 9,
    image: Property9,
    name: '1433 Beckenham Dr, Baton Rouge, LA 70808, USA',
    square: 8000,
    beds: 4,
    baths: 4,
    price: 5000,
    rating: 5,
  },
  {
    id: 10,
    image: Property10,
    name: '1574 Sharlo Ave, Baton Rouge, LA 70820, USA',
    square: 8000,
    beds: 4,
    baths: 4,
    price: 5000,
    rating: 5,
  },
  {
    id: 11,
    image: Property11,
    name: '2528 BOCAGE LAKE DR, Baton Rouge, LA 70809, USA',
    square: 8000,
    beds: 4,
    baths: 4,
    price: 5000,
    rating: 5,
  },
  {
    id: 11,
    image: Property12,
    name: '1533 NICHOLSON DR, Baton Rouge, LA 70802, USA',
    square: 8000,
    beds: 4,
    baths: 4,
    price: 5000,
    rating: 5,
  },
];

export default properties;
