import React from 'react';
import { Hexagon } from 'react-feather';

export default function Feature() {
  return (
    <>
      <div className="container mt-16 lg:mt-24">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3 className="mb-4 text-2xl font-semibold leading-normal md:text-3xl md:leading-normal">
            How It Works
          </h3>

          <p className="mx-auto max-w-xl text-slate-400">
            "Himalayaz LLC: Your Partner in Innovative AI and Software
            Solutions."
          </p>
        </div>

        <div className="mt-8 grid grid-cols-1 gap-[30px] md:grid-cols-2 lg:grid-cols-3">
          <div className="group relative overflow-hidden rounded-xl bg-transparent text-center transition-all duration-500 ease-in-out lg:px-10">
            <div className="relative -m-3 overflow-hidden text-transparent">
              <Hexagon className="mx-auto h-32 w-32 fill-blue-600/5" />
              <div className="absolute end-0 start-0 top-2/4 mx-auto flex -translate-y-2/4 items-center justify-center rounded-xl align-middle text-4xl text-blue-600 transition-all duration-500 ease-in-out">
                <i className="uil uil-estate"></i>
              </div>
            </div>

            <div className="mt-6">
              <h5 className="text-xl font-medium">Evaluate Property</h5>
              <p className="mt-3 text-slate-400">
                If the distribution of letters and 'words' is random, the reader
                will not be distracted from making.
              </p>
            </div>
          </div>

          <div className="group relative overflow-hidden rounded-xl bg-transparent text-center transition-all duration-500 ease-in-out lg:px-10">
            <div className="relative -m-3 overflow-hidden text-transparent">
              <Hexagon className="mx-auto h-32 w-32 fill-blue-600/5" />
              <div className="absolute end-0 start-0 top-2/4 mx-auto flex -translate-y-2/4 items-center justify-center rounded-xl align-middle text-4xl text-blue-600 transition-all duration-500 ease-in-out">
                <i className="uil uil-bag"></i>
              </div>
            </div>
            <div className="mt-6">
              <h5 className="text-xl font-medium">Meeting with Agent</h5>
              <p className="mt-3 text-slate-400">
                If the distribution of letters and 'words' is random, the reader
                will not be distracted from making.
              </p>
            </div>
          </div>

          <div className="group relative overflow-hidden rounded-xl bg-transparent text-center transition-all duration-500 ease-in-out lg:px-10">
            <div className="relative -m-3 overflow-hidden text-transparent">
              <Hexagon className="mx-auto h-32 w-32 fill-blue-600/5" />
              <div className="absolute end-0 start-0 top-2/4 mx-auto flex -translate-y-2/4 items-center justify-center rounded-xl align-middle text-4xl text-blue-600 transition-all duration-500 ease-in-out">
                <i className="uil uil-key-skeleton"></i>
              </div>
            </div>

            <div className="mt-6">
              <h5 className="text-xl font-medium">Close the Deal</h5>
              <p className="mt-3 text-slate-400">
                If the distribution of letters and 'words' is random, the reader
                will not be distracted from making.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
