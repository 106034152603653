import React from 'react';
import Select from 'react-select';
import { Link as Link2 } from 'react-router-dom';
import Navbar from '../component/Navbar';
import Pagination from '../component/Pagination';
import properties from '../config/grid-data';
import Switcher from '../component/Switcher';
import { Linkedin, Facebook, Twitter, Instagram } from 'react-feather';

const Houses = [
  { value: 'AF', label: 'Apartment' },
  { value: 'AZ', label: ' Offices' },
  { value: 'BS', label: 'Townhome' },
];
const minPrice = [
  { value: '1', label: '500' },
  { value: '2', label: '1000' },
  { value: '3', label: '2000' },
  { value: '4', label: '3000' },
  { value: '5', label: '4000' },
  { value: '5', label: '5000' },
  { value: '5', label: '6000' },
];
const maxPrice = [
  { value: '1', label: '500' },
  { value: '2', label: '1000' },
  { value: '3', label: '2000' },
  { value: '4', label: '3000' },
  { value: '5', label: '4000' },
  { value: '5', label: '5000' },
  { value: '5', label: '6000' },
];

export default function GridMap() {
  return (
    <>
      <Navbar topnavClass="bg-white dark:bg-slate-900" />

      <section className="relative">
        <div className="container-fluid">
          <div className="grid md:grid-cols-2 lg:grid-cols-2">
            <div>
              <div className="relative mt-12 px-3 py-8 md:p-8">
                <div className="grid grid-cols-1">
                  <form className="rounded-xl bg-white p-6 shadow-md dark:bg-slate-900 dark:shadow-gray-700">
                    <div className="registration-form text-dark text-start">
                      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 lg:gap-0">
                        <div>
                          <label className="form-label font-medium text-slate-900 dark:text-white">
                            Search : <span className="text-red-600">*</span>
                          </label>
                          <div className="filter-search-form filter-border relative mt-2">
                            <i className="uil uil-search icons"></i>
                            <input
                              name="name"
                              type="text"
                              id="job-keyword"
                              className="form-input filter-input-box border-0 bg-gray-50 dark:bg-slate-800"
                              placeholder="Search your Keywords"
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="buy-properties"
                            className="form-label font-medium text-slate-900 dark:text-white">
                            Select Categories:
                          </label>
                          <div className="filter-search-form filter-border relative mt-2">
                            <i className="uil uil-estate icons"></i>
                            <Select
                              className="form-input filter-input-box border-0 bg-gray-50 dark:bg-slate-800"
                              options={Houses}
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="buy-min-price"
                            className="form-label font-medium text-slate-900 dark:text-white">
                            Min Price :
                          </label>
                          <div className="filter-search-form filter-border relative mt-2">
                            <i className="uil uil-usd-circle icons"></i>
                            <Select
                              className="form-input filter-input-box border-0 bg-gray-50 dark:bg-slate-800"
                              options={minPrice}
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="buy-max-price"
                            className="form-label font-medium text-slate-900 dark:text-white">
                            Max Price :
                          </label>
                          <div className="filter-search-form relative mt-2">
                            <i className="uil uil-usd-circle icons"></i>
                            <Select
                              className="form-input filter-input-box border-0 bg-gray-50 dark:bg-slate-800"
                              options={maxPrice}
                            />
                          </div>
                        </div>

                        <div className="lg:mt-6">
                          <input
                            type="submit"
                            id="search-buy"
                            name="search"
                            className="btn searchbtn submit-btn !h-12 w-full rounded border-blue-600 bg-blue-600 text-white hover:border-blue-700 hover:bg-blue-700"
                            value="Search"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <Pagination
                  itemsPerPage={6}
                  items={properties}
                  gridClass={`grid lg:grid-cols-2 grid-cols-1 mt-8 gap-[30px]`}
                />
              </div>

              <footer className="relative bg-neutral-900">
                <div className="px-0 py-[30px]">
                  <div className="container px-6 text-center">
                    <div className="grid items-center gap-6 md:grid-cols-2">
                      <div className="text-center md:text-left">
                        <p className="mb-0 text-gray-300">
                          © {new Date().getFullYear()} Hously. Design & Develop
                          with <i className="mdi mdi-heart text-red-600"></i> by{' '}
                          <a
                            rel="noreferrer"
                            href="https://shreethemes.in/"
                            target="_blank"
                            className="text-reset">
                            Shreethemes
                          </a>
                          .
                        </p>
                      </div>

                      <ul className="list-none text-center md:text-right">
                        <li className="ms-1 inline">
                          <Link2
                            to="#"
                            className="btn btn-icon btn-sm rounded-md border !border-gray-800 text-gray-400 hover:border-blue-600 hover:bg-blue-600 hover:text-white dark:hover:border-blue-600 dark:hover:bg-blue-600">
                            <Facebook className="h-4 w-4" />
                          </Link2>
                        </li>
                        <li className="ms-1 inline">
                          <Link2
                            to="#"
                            className="btn btn-icon btn-sm rounded-md border !border-gray-800 text-gray-400 hover:border-blue-600 hover:bg-blue-600 hover:text-white dark:hover:border-blue-600 dark:hover:bg-blue-600">
                            <Instagram className="h-4 w-4" />
                          </Link2>
                        </li>
                        <li className="ms-1 inline">
                          <Link2
                            to="#"
                            className="btn btn-icon btn-sm rounded-md border !border-gray-800 text-gray-400 hover:border-blue-600 hover:bg-blue-600 hover:text-white dark:hover:border-blue-600 dark:hover:bg-blue-600">
                            <Twitter className="h-4 w-4" />
                          </Link2>
                        </li>
                        <li className="ms-1 inline">
                          <Link2
                            to="#"
                            className="btn btn-icon btn-sm rounded-md border !border-gray-800 text-gray-400 hover:border-blue-600 hover:bg-blue-600 hover:text-white dark:hover:border-blue-600 dark:hover:bg-blue-600">
                            <Linkedin className="h-4 w-4" />
                          </Link2>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </footer>
            </div>

            <div className="relative hidden md:block">
              <div className="map fixed w-full border-0 leading-[0]">
                <iframe
                  title="grid-iframe"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                  style={{ border: 0 }}
                  className="h-screen w-full"
                  allowFullScreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Switcher />
    </>
  );
}
