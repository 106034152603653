import React from 'react';
import { Link as Link2 } from 'react-router-dom';
import Navbar from '../component/Navbar';

import Poperty1Image from '../assets/images/property/1.jpg';
import Poperty2Image from '../assets/images/property/2.jpg';
import Poperty3Image from '../assets/images/property/3.jpg';
import Poperty4Image from '../assets/images/property/4.jpg';
import Poperty5Image from '../assets/images/property/5.jpg';
import Poperty6Image from '../assets/images/property/6.jpg';
import Footer from '../component/Footer';
import Switcher from '../component/Switcher';

import {
  Linkedin,
  Facebook,
  Twitter,
  Instagram,
  GitHub,
  Gitlab,
  Youtube,
} from 'react-feather';

export default function BlogSidebar() {
  const blogList = [
    {
      title: 'Skills That You Can Learn In The Real Estate Market',
      date: '3rd March, 2023',
      type: 'Industrial',
      image: Poperty1Image,
    },
    {
      title: 'Learn The Truth About Real Estate Industry',
      date: '3rd March, 2023',
      type: 'Industrial',
      image: Poperty2Image,
    },
    {
      title: '10 Quick Tips About Business Development',
      date: '3rd March, 2023',
      type: 'Industrial',
      image: Poperty3Image,
    },
    {
      title: '14 Common Misconceptions About Business Development',
      date: '3rd March, 2023',
      type: 'Industrial',
      image: Poperty4Image,
    },
    {
      title: '10 Things Your Competitors Can Teach You About Real Estate',
      date: '3rd March, 2023',
      type: 'Industrial',
      image: Poperty5Image,
    },
    {
      title: 'Why We Love Real Estate',
      date: '3rd March, 2023',
      type: 'Industrial',
      image: Poperty6Image,
    },
  ];

  return (
    <>
      <Navbar navClass="navbar-white" />
      {/* <!-- Start Hero --> */}
      <section className="relative table w-full bg-[url('../../assets/images/bg/01.jpg')] bg-cover bg-center bg-no-repeat py-32 lg:py-36">
        <div className="absolute inset-0 bg-black opacity-80"></div>
        <div className="container">
          <div className="mt-10 grid grid-cols-1 text-center">
            <h3 className="text-3xl font-medium leading-normal text-white md:text-4xl md:leading-normal">
              Blogs & News
            </h3>
          </div>
        </div>
      </section>
      <div className="relative">
        <div className="shape z-1 overflow-hidden text-white dark:text-slate-900">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"></path>
          </svg>
        </div>
      </div>
      {/* <!-- End Hero --> */}

      {/* <!-- Start --> */}

      <section className="relative py-16 md:py-24">
        <div className="container">
          <div className="grid grid-cols-1 gap-[30px] md:grid-cols-2 lg:grid-cols-12">
            <div className="order-2 md:order-1 lg:col-span-8">
              <div className="grid grid-cols-1 gap-[30px] lg:grid-cols-2">
                {blogList.map((item, index) => (
                  <div
                    key={index}
                    className="group relative h-fit overflow-hidden rounded-xl bg-white shadow transition-all duration-500 hover:-mt-[5px] dark:bg-slate-900 dark:shadow-gray-700">
                    <div className="relative overflow-hidden">
                      <img src={item.image} className="" alt="" />
                      <div className="absolute end-4 top-4">
                        <span className="h-5 rounded-full bg-blue-600 px-2.5 py-1 text-[14px] font-medium text-white">
                          {item.type}
                        </span>
                      </div>
                    </div>

                    <div className="relative p-6">
                      <div className="">
                        <div className="mb-4 flex justify-between">
                          <span className="text-sm text-slate-400">
                            <i className="uil uil-calendar-alt me-2 text-slate-900 dark:text-white"></i>
                            {item.date}
                          </span>
                          <span className="ms-3 text-sm text-slate-400">
                            <i className="uil uil-clock me-2 text-slate-900 dark:text-white"></i>
                            5 min read
                          </span>
                        </div>

                        <Link2
                          to="/blog-detail"
                          className="title text-xl font-medium duration-500 ease-in-out hover:text-blue-600">
                          {item.title}
                        </Link2>

                        <div className="mt-3">
                          <Link2
                            to="/blog-detail"
                            className="btn btn-link duration-500 ease-in-out after:bg-blue-600 hover:text-blue-600">
                            Read More <i className="uil uil-arrow-right"></i>
                          </Link2>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="order-1 md:order-2 lg:col-span-4">
              <div className="sticky top-20">
                <form>
                  <div>
                    <label htmlFor="searchname" className="text-lg font-medium">
                      Search Properties
                    </label>
                    <div className="relative mt-2">
                      <i className="uil uil-search absolute start-3 top-[8px] text-lg"></i>
                      <input
                        name="search"
                        id="searchname"
                        type="text"
                        className="form-input border border-slate-100 ps-10 dark:border-slate-800"
                        placeholder="Search"
                      />
                    </div>
                  </div>
                </form>

                <h5 className="mt-[30px] text-lg font-medium">Recent post</h5>
                <div className="mt-4 flex items-center">
                  <img
                    src={Poperty6Image}
                    className="h-16 rounded-md shadow dark:shadow-gray-800"
                    alt=""
                  />

                  <div className="ms-3">
                    <Link2 to="#" className="font-medium hover:text-blue-600">
                      10 Things You About Real Estate
                    </Link2>
                    <p className="text-sm text-slate-400">2nd March 2023</p>
                  </div>
                </div>

                <div className="mt-4 flex items-center">
                  <img
                    src={Poperty3Image}
                    className="h-16 rounded-md shadow dark:shadow-gray-800"
                    alt=""
                  />

                  <div className="ms-3">
                    <Link2 to="#" className="font-medium hover:text-blue-600">
                      Why We Love Real Estate
                    </Link2>
                    <p className="text-sm text-slate-400">2nd March 2023</p>
                  </div>
                </div>

                <div className="mt-4 flex items-center">
                  <img
                    src={Poperty5Image}
                    className="h-16 rounded-md shadow dark:shadow-gray-800"
                    alt=""
                  />

                  <div className="ms-3">
                    <Link2 to="#" className="font-medium hover:text-blue-600">
                      110 Quick Tips About Real Estate
                    </Link2>
                    <p className="text-sm text-slate-400">2nd March 2023</p>
                  </div>
                </div>

                <h5 className="mt-[30px] text-lg font-medium">Social sites</h5>
                <ul className="mt-4 list-none">
                  <li className="ms-1 inline">
                    <Link2
                      to="#"
                      className="btn btn-icon btn-sm rounded-md border border-gray-100 text-slate-400 hover:border-blue-600 hover:bg-blue-600 hover:text-white dark:border-gray-800">
                      <Facebook className="h-4 w-4" />{' '}
                    </Link2>
                  </li>
                  <li className="ms-1 inline">
                    <Link2
                      to="#"
                      className="btn btn-icon btn-sm rounded-md border border-gray-100 text-slate-400 hover:border-blue-600 hover:bg-blue-600 hover:text-white dark:border-gray-800">
                      <Instagram className="h-4 w-4" />{' '}
                    </Link2>
                  </li>
                  <li className="ms-1 inline">
                    <Link2
                      to="#"
                      className="btn btn-icon btn-sm rounded-md border border-gray-100 text-slate-400 hover:border-blue-600 hover:bg-blue-600 hover:text-white dark:border-gray-800">
                      <Twitter className="h-4 w-4" />
                    </Link2>
                  </li>
                  <li className="ms-1 inline">
                    <Link2
                      to="#"
                      className="btn btn-icon btn-sm rounded-md border border-gray-100 text-slate-400 hover:border-blue-600 hover:bg-blue-600 hover:text-white dark:border-gray-800">
                      <Linkedin className="h-4 w-4" />
                    </Link2>
                  </li>
                  <li className="ms-1 inline">
                    <Link2
                      to="#"
                      className="btn btn-icon btn-sm rounded-md border border-gray-100 text-slate-400 hover:border-blue-600 hover:bg-blue-600 hover:text-white dark:border-gray-800">
                      <GitHub className="h-4 w-4" />{' '}
                    </Link2>
                  </li>
                  <li className="ms-1 inline">
                    <Link2
                      to="#"
                      className="btn btn-icon btn-sm rounded-md border border-gray-100 text-slate-400 hover:border-blue-600 hover:bg-blue-600 hover:text-white dark:border-gray-800">
                      <Youtube className="h-4 w-4" />
                    </Link2>
                  </li>
                  <li className="ms-1 inline">
                    <Link2
                      to="#"
                      className="btn btn-icon btn-sm rounded-md border border-gray-100 text-slate-400 hover:border-blue-600 hover:bg-blue-600 hover:text-white dark:border-gray-800">
                      <Gitlab className="h-4 w-4" />
                    </Link2>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Switcher />
      <Footer />
    </>
  );
}
