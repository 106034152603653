import React from 'react';
import { Link as Link2 } from 'react-router-dom';
import Navbar from '../component/Navbar';
import { Search } from 'react-feather';

import BackgroundImage from '../assets/images/bg/01.jpg';
import Property from '../component/Properties/property';
import Feature from '../component/Feature';
import Footer from '../component/Footer';
import BuyTab from '../component/Buy-tab';
import Switcher from '../component/Switcher';

export default function Buy() {
  return (
    <>
      <Navbar navClass="navbar-white" />
      {/* <!-- Start Hero --> */}
      <section
        style={{ backgroundImage: `url(${BackgroundImage})` }}
        className="relative table w-full bg-[url('../../assets/images/bg/01.jpg')] bg-cover bg-center bg-no-repeat py-32 lg:py-36">
        <div className="absolute inset-0 bg-black opacity-80"></div>
        <div className="container">
          <div className="mt-10 grid grid-cols-1 text-center">
            <h3 className="text-3xl font-medium leading-normal text-white md:text-4xl md:leading-normal">
              Find Your Dream Home
            </h3>
          </div>
        </div>
      </section>
      <div className="relative">
        <div className="shape z-1 overflow-hidden text-white dark:text-slate-900">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"></path>
          </svg>
        </div>
      </div>

      <section className="relative pb-16 md:pb-24">
        <div className="container relative -mt-[25px]">
          <div className="grid grid-cols-1">
            <div className="subcribe-form z-1">
              <form className="relative mx-auto max-w-2xl">
                <Search className="absolute start-4 top-[47%] h-5 w-5 -translate-y-1/2"></Search>
                <input
                  type="name"
                  id="search_name"
                  name="name"
                  className="rounded-md bg-white ps-12 shadow dark:bg-slate-900 dark:shadow-gray-700"
                  placeholder="City, Address, Zip :"
                />
                <button
                  type="submit"
                  className="btn rounded-md bg-blue-600 text-white hover:bg-blue-700">
                  Search
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* End Hero  */}
        <Property />
        <Feature />
        <BuyTab />
        <div className="container mt-16 lg:mt-24">
          <div className="grid grid-cols-1 text-center">
            <h3 className="mb-6 text-2xl font-medium leading-normal text-black dark:text-white md:text-3xl md:leading-normal">
              Have Question ? Get in touch!
            </h3>

            <p className="mx-auto max-w-xl text-slate-400">
              Himalayaz LLC: Your Partner in Innovative AI and Software
              Solutions.
            </p>

            <div className="mt-6">
              <Link2
                to="/contact"
                className="btn rounded-md bg-blue-600 text-white hover:bg-blue-700">
                <i className="uil uil-phone me-2 align-middle"></i> Contact us
              </Link2>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Switcher />
    </>
  );
}
