import React from 'react';
import TinySlider from 'tiny-slider-react';

import 'tiny-slider/dist/tiny-slider.css';

import avatar1 from '../assets/images/client/01.jpg';
import avatar2 from '../assets/images/client/02.jpg';
import avatar3 from '../assets/images/client/03.jpg';
import avatar4 from '../assets/images/client/04.jpg';

const settings = {
  items: 1,
  controls: false,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: 'bottom',
  speed: 400,
  gutter: 16,
};

export default function Client() {
  const review = [
    {
      id: '1',
      profile: avatar1,
      name: 'Indus Valley',
      designation: 'CEO',
      description:
        'Working with Himalayaz LLC has been an exceptional experience. Their expertise in AI and software services is truly remarkable. From the moment we engaged with them, the Himalayaz LLC team exhibited exceptional professionalism and a deep understanding of our needs.',
    },
    {
      id: '2',
      profile: avatar2,
      name: 'Christa Smith',
      designation: 'Manager',
      description:
        'Their approach to project management is flawless; they meticulously handle every aspect of the process. Their attention to detail, commitment to timelines, and clear communication made our project progress seamlessly. We were impressed by their ability to translate our vision into a tangible, high-quality software solution.',
    },
    {
      id: '3',
      profile: avatar3,
      name: 'Christa Smith',
      designation: 'Manager',
      description: `The AI and software services provided by Himalayaz LLC have exceeded our expectations. Their team's technical prowess and dedication have been instrumental in achieving our goals efficiently and effectively`,
    },
    {
      id: '4',
      profile: avatar4,
      name: 'Christa Smith',
      designation: 'Manager',
      description: `We are genuinely delighted with the results and the way Himalayaz LLC managed everything. It's clear that they are passionate about what they do, and it reflects in the exceptional quality of their work. We look forward to continuing our collaboration and highly recommend Himalayaz LLC to anyone seeking top-notch AI and software services`,
    },
  ];

  return (
    <>
      <div className="container mt-16 lg:mt-24">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3 className="mb-4 text-2xl font-semibold leading-normal md:text-3xl md:leading-normal">
            What Our Client Say ?
          </h3>

          <p className="mx-auto max-w-xl text-slate-400">
            Himalayaz LLC: Your Partner in Innovative AI and Software Solutions.
          </p>
        </div>

        <div className="relative mt-16 flex justify-center">
          <div className="relative w-full md:w-1/2 lg:w-1/3">
            <div className="absolute -start-0 -top-20 md:-start-24">
              <i className="mdi mdi-format-quote-open text-9xl opacity-5"></i>
            </div>

            <div className="absolute -end-0 bottom-28 md:-end-24">
              <i className="mdi mdi-format-quote-close text-9xl opacity-5"></i>
            </div>

            <div className="tiny-single-item">
              <TinySlider settings={settings}>
                {review.map((el, index) => (
                  <div className="tiny-slide" key={index}>
                    <div className="text-center">
                      <p className="text-xl italic text-slate-400">
                        {' '}
                        " {el.description} "{' '}
                      </p>

                      <div className="mt-5 text-center">
                        <ul className="mb-2 list-none text-xl font-medium text-amber-400">
                          <li className="ms-1 inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="ms-1 inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="ms-1 inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="ms-1 inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="ms-1 inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                        </ul>

                        <img
                          src={el.profile}
                          className="mx-auto h-14 w-14 rounded-full shadow-md dark:shadow-gray-700"
                          alt=""
                        />
                        <h6 className="fw-semibold mt-2">{el.name}</h6>
                        <span className="text-sm text-slate-400">
                          {el.designation}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </TinySlider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
