import React, { useState } from 'react';
import { Link as Link2 } from 'react-router-dom';

import Icon from '../../assets/images/logo-icon-64.png';
// import BackgroudImage from '../../assets/images/bg/01.jpg';
import BackgroudImage from '../../assets/images/himalayaz-1.jpg';

import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../config/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [checked, setChecked] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [error, setError] = useState(false);
  const [emailMassage, setEmailMassage] = useState('');

  const navigate = useNavigate();

  const particlesInit = async main => {
    // console.log(main);
    // initialize the tsParticles instance
    await loadFull(main);
  };
  const particlesLoaded = container => {
    // console.log(container);
  };

  const errorHandle = () => {
    if (!email || !password) {
      setError(true);
      return;
    }
  };

  const emailValidation = email => {
    if (!email) {
      setEmailMassage('Email is required');
    } else {
      const regEx =
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zAZ0-9]{2,4})+$/;
      if (regEx.test(email)) {
        setEmailMassage('');
        return true; // Email is valid
      } else {
        setEmailMassage('Email is not valid');
        return false; // Email is not valid
      }
    }
  };

  const Login = () => {
    const isemailValid = emailValidation(email);
    console.log(' btn workks');

    if (isemailValid && password.length >= 8) {
      console.log('if workks');
      setBtnDisable(true);
      signInWithEmailAndPassword(auth, email, password)
        .then(userCredential => {
          toast.success('Sign in successfully', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });

          const user = userCredential.user;
          // dispatch({ type: "user", user });
          let userName = user.displayName;
          let userEmail = user.email;
          let userTime = user.metadata.creationTime;
          let userId = user.uid;
          const userObj = {
            userName,
            userEmail,
            userTime,
            userId,
          };
          sessionStorage.setItem('UserInfo', JSON.stringify(userObj));
          setTimeout(() => {
            navigate('/');
          }, 3000);
        })
        .catch(error => {
          setBtnDisable(false);
          toast.error('Invaild Username or Password', {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          // const errorMessage = error.message;
          console.log('errorMessage', error?.response);
        });
    } else {
      console.log('else workks');
      setError(true);
      errorHandle();
      emailValidation(email);
    }
  };

  console.log(error);

  return (
    <>
      <section className="zoom-image py-36 relative flex items-center overflow-hidden md:h-screen">
        <div
          style={{ backgroundImage: `url(${BackgroudImage})` }}
          className="image-wrap z-1 absolute inset-0 bg-cover bg-center bg-no-repeat"></div>
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          className="z-2 absolute inset-0 bg-gradient-to-b from-transparent to-black"
          options={{
            particles: {
              number: {
                value: 250,
                density: {
                  enable: false,
                  value_area: 800,
                },
              },
              color: {
                value: '#ffffff',
              },
              shape: {
                type: 'circle',
                stroke: {
                  width: 0,
                  color: '#000000',
                },
                polygon: {
                  nb_sides: 36,
                },
                image: {
                  src: '',
                  width: 1000,
                  height: 1000,
                },
              },
              opacity: {
                value: 0.5,
                random: false,
                anim: {
                  enable: false,
                  speed: 0.5,
                  opacity_min: 1,
                  sync: false,
                },
              },
              size: {
                value: 3.2,
                random: true,
                anim: {
                  enable: false,
                  speed: 20,
                  size_min: 0.1,
                  sync: false,
                },
              },
              line_linked: {
                enable: false,
                distance: 100,
                color: '#ffffff',
                opacity: 0.4,
                width: 2,
              },
              move: {
                enable: true,
                speed: 1,
                direction: 'bottom',
                random: false,
                straight: false,
                out_mode: 'out',
                bounce: false,
                attract: {
                  enable: false,
                  rotateX: 800,
                  rotateY: 1200,
                },
              },
            },
            interactivity: {
              detect_on: 'canvas',
              events: {
                onhover: {
                  enable: false,
                  mode: 'repulse',
                },
                onclick: {
                  enable: false,
                  mode: 'push',
                },
                resize: true,
              },
              modes: {
                grab: {
                  distance: 200,
                  line_linked: {
                    opacity: 1,
                  },
                },
                bubble: {
                  distance: 400,
                  size: 40,
                  duration: 2,
                  opacity: 8,
                  speed: 3,
                },
                repulse: {
                  distance: 71,
                  duration: 0.4,
                },
                push: {
                  particles_nb: 4,
                },
                remove: {
                  particles_nb: 2,
                },
              },
            },
            retina_detect: true,
          }}
        />
        <div className="z-3 container">
          <div className="flex justify-center">
            <div className="dark:bg-slate-900 dark:shadow-gray-700 m-auto w-full max-w-[400px] rounded-md bg-white p-6 shadow-md">
              {/* <Link2 to="/index"><img src={Icon} className="mx-auto" alt="" /></Link2> */}
              <h5 className="my-6 text-xl font-semibold">Login</h5>
              <div className="ltr:text-left rtl:text-right">
                <div className="grid grid-cols-1">
                  <div className="mb-4">
                    <label className="font-medium" htmlFor="LoginEmail">
                      Email Address:
                    </label>
                    <input
                      id="LoginEmail"
                      type="email"
                      onChange={e => {
                        setEmail(e.target.value);
                      }}
                      className="form-input mt-3"
                      placeholder="name@example.com"
                    />
                    {error && emailMassage && (
                      <p className="pt-2  text-center text-red-600">
                        {emailMassage}
                      </p>
                    )}
                  </div>

                  <div className="mb-4">
                    <label className="font-medium" htmlFor="LoginPassword">
                      Password:
                    </label>
                    <input
                      onChange={e => {
                        setPassword(e.target.value);
                      }}
                      id="LoginPassword"
                      type="password"
                      className="form-input mt-3"
                      placeholder="Password:"
                    />
                    {error && password.length <= 0 ? (
                      <p className="pt-2  text-center text-red-600">
                        Password requried
                      </p>
                    ) : (
                      error &&
                      password.length <= 7 && (
                        <p className="pt-2  text-center text-red-600">
                          Password must be atleast 8 character long
                        </p>
                      )
                    )}
                  </div>

                  <div className="mb-4 flex justify-between">
                    <div className="inline-flex items-center">
                      <input
                        className="form-checkbox me-2 rounded border border-inherit h-4 w-4 text-blue-600"
                        type="checkbox"
                        checked={checked}
                        onChange={e => {
                          setChecked(!checked);
                        }}
                        value=""
                        id="RememberMe"
                      />
                      <label
                        className="form-check-label text-slate-400"
                        htmlFor="RememberMe">
                        Remember me
                      </label>
                    </div>

                    <p className="text-slate-400 mb-0">
                      <Link2
                        to="/auth-reset-password"
                        className="text-slate-400  hover:underline">
                        Forgot password ?
                      </Link2>
                    </p>
                  </div>

                  <div className="mb-4">
                    <button
                      disabled={btnDisable}
                      onClick={Login}
                      className="btn w-full rounded-md bg-blue-600 text-white hover:bg-blue-800">
                      Sign in
                    </button>
                  </div>

                  <div className="text-center">
                    <span className="me-2 text-slate-400">
                      Don't have an account ?
                    </span>{' '}
                    <Link2
                      to="/auth-signup"
                      className="font-bold text-black  hover:underline dark:text-white">
                      Sign Up
                    </Link2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
